import React from "react";
import ImagesUploader from "./ImagesUploader";

function ImagesViewItem(props) {
  const valueAsArray = () => {
    const { value } = props;
    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  };

  // Works when this code is commented out:
  /*if (!valueAsArray().length) {
    return null;
  }*/

  return (
    <ImagesUploader
      max={props.max}
      readonly={props.readonly}
      value={valueAsArray()}
      onChange={props.onChange}
    />
  );
}

export default ImagesViewItem;
