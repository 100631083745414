import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

class LigaRules extends Component {
	componentDidMount() {}

	render() {
		return (
			<Container>
				<Row>
					<Col xs="12">
						<h1 className="page-title">
							Hvem kan deltage i Adventurespejdligaen?
						</h1>
					</Col>
					<Col xs="12">
						<p className="text-lg-start">
							Helt overordnet er reglerne tænkt meget enkelt, så det er muligt
							for alle storspejdere/tropspejdere i alderen 12-17 år at blive en
							del af ligaen. Det kræver blot, at du tilmelder din patrulje, og I
							er velkomne uanset hvilket korps, I er fra.
						</p>
					</Col>
					<Col xs="12">
						<h2 className="page-title">Regler for at deltage som patrulje</h2>
					</Col>
					<Col xs="12" className="text-lg-start">
						<ul className="section-sign-list">
							<li>
								1 &nbsp; En patrulje er defineret med et fast patruljenavn
								igennem hele sæsonen. <br></br> - Ændring af navn inden første
								løb kan lade sig gøre, derefter er det fast.
							</li>
							<li>2 &nbsp; En patrulje kan højst bestå af 8 medlemmer</li>
							<li>
								3 &nbsp; Der kan ikke opnås point til ligaen, hvis man har
								deltagere i patruljen på et løb, der ikke er registreret i
								liga-patruljen inden løbsstart.
							</li>
							<li>
								4 &nbsp; En ligapatrulje kan kun sende en patrulje afsted på
								hvert løb - og må derfor ikke dele sig
							</li>
							<li>
								5 &nbsp; For sjakløb, hvor patruljestørrelsen skal være på tre
								personer eller derunder har en patrulje mulighed for at tilmelde
								sig med mere end et sjak. <br></br> - Det bedst placerede sjak
								giver points til patruljen. <br></br> - Ved deltagelse på
								sådanne løb gives der dispensation for reglen omkring fast
								patruljenavn. <br></br>{" "}
								<em>
									Vær dog opmærksom på, at anvende det rigtige patrulje-id.
								</em>
							</li>
							<li>
								6 &nbsp; Patruljer kan tilmelde sig Adventurespejdligaen løbende
								gennem en sæson.
							</li>
							<li>
								7 &nbsp; En person kan være tilknyttet flere patruljer i ligaen
							</li>
							<li>
								8 &nbsp; Der kan i løbet af en sæson registreres yderligere
								personer i patruljen.
							</li>
							<li>
								9 &nbsp; Når en person er registreret i patruljen, gælder det
								for resten af sæsonen. <br></br> - Hvis der skulle opstå
								komplikationer med dette, skal liga-teamet kontaktes og sagen
								behandles derefter individuelt.
							</li>
							<li>
								10 &nbsp; De enkelte løb har selvbestemmelse ift. pointgivning
								på løbene. <br></br> - Kommentarer eller opdagede fejl skal
								henvendes til de enkelte løb, der selvstændigt tager stilling
								til, om der skal rettes i stillingen. Løbene melder dette til
								ligaens point-ansvarlige.
							</li>
						</ul>
					</Col>
					<Col xs="12">
						<h2 className="page-title">Pointgivning i ligaen</h2>
					</Col>
					<Col xs="12" className="text-lg-start">
						<ul>
							<li>
								Alle løb i ligaen giver samme pointsats (vægtes ens). På Nathejk
								gives dog 20 ligapoint for at nå i mål inden for tidsfristen,
								eller 100 ligapoint for at nå alle poster inkl. mål inden for
								tidsfristerne, uden at blive fanget undervejs.
							</li>
							<li>
								Patruljens opnåede placering på løbet omregnes til
								placeringspoint i ligaen efter skalaen: 100, 93, 86, 79, 72, 69,
								66, 63, 60, 57, 54, 51, 48, 46, 44, 42, 40, 38, 36, 34, 32, 30,
								28, 26, 24, 22, 20, 18, 16, 14, 12, 10, 8, 7, 6, 5, 4, 3, 2, 1
							</li>
							<li>
								En placering efter nummer 40 giver derfor ingen placeringspoint
							</li>
							<li>
								Patruljens placeringspoint er summen af patruljens seks bedste
								placeringer i den samlede sæson.
							</li>
							<li>
								Hvis en patrulje diskvalificeres på et løb, på grund af snyd,
								giver det -100 ligapoint.
							</li>
						</ul>
					</Col>
					<Col xs="12">
						<h2 className="page-title">Afslutning af ligaen</h2>
					</Col>
					<Col xs="12" className="text-lg-start">
						<p>
							Ved sæsonens afslutning opgøres det samlede antal points for hver
							patrulje. Ved pointlighed tælles antallet af 1. pladser. Ved
							stadig pointlighed tælles alle vinderpoints med. Ved stadig
							pointlighed deles titlen som vinder af Adventurespejdligaen.
						</p>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default LigaRules;
