import axios from "axios";
import { GET_MY_OPEN_SQUADS_LIST, GET_SEASONS } from "./types";

const tenant = process.env.REACT_APP_TENANT;
const baseURL = process.env.REACT_APP_BASEURL + "/tenant/" + tenant;

export const getMyOpenSeasons = () => async (dispatch, getState) => {
  return await axios
    .get(baseURL + `/season/findOpenByMember`, setHeaders(getState))
    .then((res) =>
      dispatch({
        type: GET_MY_OPEN_SQUADS_LIST,
        payload: res.data,
      })
    );
};

export const getSeasons = () => async (dispatch, getState) => {
  return await axios
    .get(baseURL + `/season`, setHeaders(getState))
    .then((res) =>
      dispatch({
        type: GET_SEASONS,
        payload: res.data,
      })
    );
};

// Config headers and token
const setHeaders = (getState) => {
  // Get token from localstorage
  const token = getState().auth.token;
  // Headers
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  // If token, add to headers
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
};
