import React, { Component } from "react";
import logo_white from '../assets/images/logo_white.png';
import logo_black from '../assets/images/logo_black.png';

class Logo extends Component {

	render(){
		return (this.props.light ? 
			<img style={{minHeight: "40px", maxHeight: "100px" }} className={this.props.className} src={logo_white} alt="Adventurespejd logo" /> 
				:
		 	<img style={{minHeight: "40px", maxHeight: "100px" }} className={this.props.className} src={logo_black} alt="Adventurespejd logo" />
		)
	}
}

Logo.defaultProps = {
	light: true
}

export default Logo;