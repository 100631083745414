import React, { Component } from "react";
import {
	Dropdown,
	DropdownButton,
	Row,
	Col,
	Button,
	Form,
	FormGroup,
	InputGroup,
	Alert,
} from "react-bootstrap";
import { getRacesByMember, getRaceByID } from "../../actions/raceActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateRace } from "../../actions/raceActions";
import { color, outline } from "../../assets/enums";
import ImagesViewItem from "../utilities/ImagesViewItem";

class MyRaces extends Component {
	state = {
		currentRace: "Vælg løb",
		currentRaceId: null,
		myRacesList: [],
		editable: false,
		validErrorHeading: null,
		validErrorMsg: null,
		validErrorVariant: null,
		// Form State
		isDisabled: true,
		shortDescription: "",
		description: "",
		minSquadSize: "",
		maxSquadSize: "",
		minAge: "",
		maxAge: "",
		contactEmail: "",
		snapchatUsername: "",
		facebookUrl: "",
		instagramUrl: "",
		youtubeUrl: "",
		url: "",
		participants: "",
		geographic: "",
		registrationUrl: "",
		minDistance: "",
		startDate: "",
		endDate: "",
		period: "",
		swimmingRequired: false,
		facts: [],
		competitionClasses: [],
		hexColor: "",
		coverImage: [],
		featuredImages: [],
		logo: [],
	};

	static propTypes = {
		auth: PropTypes.object.isRequired,
		getRacesByMember: PropTypes.object.isRequired,
		getRaceByID: PropTypes.object.isRequired,
		updateRace: PropTypes.func.isRequired,
	};

	componentDidMount() {
		const tempRaceList = this.props.getRacesByMember(this.props.auth.user.id);
		tempRaceList.then((res) => {
			this.setState({
				myRacesList: res.payload,
				currentRace: res.payload[0].name,
				currentRaceId: res.payload[0].id,
			});
			this.props.getRaceByID(res.payload[0].id).then((res) => {
				this.setInitState();
			});
		});
	}

	setInitState() {
		const race = this.props.race.race;
		this.setState({
			startDate: race.startDate ? race.startDate : "",
			endDate: race.endDate ? race.endDate : "",
			swimmingRequired: race.swimmingRequired,
			facts: this.decode(race.facts),
			competitionClasses: this.decode(race.competitionClasses),
			hexColor: race.hexColor,
			coverImage: race.coverImage,
			featuredImages: race.featuredImages,
			logo: race.logo,
		});
	}

	selectRace(raceName) {
		if (raceName !== this.state.currentRace) {
			const race = this.state.myRacesList.find((race) => {
				return race.name === raceName;
			});
			this.setState({ currentRace: raceName, currentRaceId: race.id });
			this.props.getRaceByID(race.id).then((res) => {
				this.setInitState();
			});
		}
	}

	decode(encodedString) {
		const slotsAllowed = 5;
		let newFaqArray = encodedString !== null ? encodedString.split("|") : [];
		const length = newFaqArray.length;
		const emptySlots = length === slotsAllowed ? 0 : slotsAllowed - length;

		if (emptySlots > 0) {
			for (var i = 0; i < emptySlots; i++) {
				newFaqArray = newFaqArray.concat("");
			}
		}
		return newFaqArray;
	}

	encode(decodedString) {
		let newEncode = "";
		for (var i = 0; i < decodedString.length; i++) {
			if (decodedString[i].trim() !== "") {
				const element = decodedString[i].replace("|", "-");
				newEncode += element + "|";
			}
		}
		return newEncode.slice(0, -1);
	}

	setFormEditable(state) {
		this.setState({ editable: state });
		if (state === false) {
			this.resetState();
		} else {
			this.fillState();
		}
	}

	fillState() {
		const race = this.props.race.race;
		this.setState({
			minSquadSize: race.minSquadSize,
			maxSquadSize: race.maxSquadSize,
			minAge: race.minAge,
			maxAge: race.maxAge,
			contactEmail: race.contactEmail,
			snapchatUsername: race.snapchatUsername,
			facebookUrl: race.facebookUrl ? race.facebookUrl.split("//")[1] : "",
			instagramUrl: race.instagramUrl ? race.instagramUrl.split("//")[1] : "",
			youtubeUrl: race.youtubeUrl ? race.youtubeUrl.split("//")[1] : "",
			url: race.url ? race.url.split("//")[1] : "",
			participants: race.participants,
			geographic: race.geographic,
			registrationUrl: race.registrationUrl
				? race.registrationUrl.split("//")[1]
				: "",
			minDistance: race.minDistance,
			startDate: race.startDate,
			endDate: race.endDate,
			period: race.period,
			swimmingRequired: race.swimmingRequired,
			shortDescription: race.shortDescription,
			description: race.description,
			facts: this.decode(race.facts),
			competitionClasses: this.decode(race.competitionClasses),
			hexColor: race.hexColor,
			coverImage: race.coverImage,
			featuredImages: race.featuredImages,
			logo: race.logo,
		});
	}

	resetState() {
		const race = this.props.race.race;
		this.setState({
			minSquadSize: "",
			maxSquadSize: "",
			minAge: "",
			maxAge: "",
			contactEmail: "",
			snapchatUsername: "",
			facebookUrl: "",
			instagramUrl: "",
			youtubeUrl: "",
			url: "",
			participants: "",
			geographic: "",
			registrationUrl: "",
			minDistance: "",
			startDate: race.startDate ? race.startDate : "",
			endDate: race.endDate ? race.endDate : "",
			period: "",
			swimmingRequired: race.swimmingRequired,
			shortDescription: "",
			description: "",
			facts: this.decode(race.facts),
			competitionClasses: this.decode(race.competitionClasses),
			validErrorHeading: null,
			validErrorMsg: null,
			validErrorVariant: null,
			hexColor: race.hexColor,
			coverImage: race.coverImage,
			featuredImages: race.featuredImages,
			logo: race.logo,
		});
	}

	anyChanges() {
		const race = this.props.race.race;
		const state = this.state;
		return (
			parseInt(state.minSquadSize) !== race.minSquadSize ||
			parseInt(state.maxSquadSize) !== race.maxSquadSize ||
			state.minAge !== race.minAge ||
			parseInt(state.maxAge) !== race.maxAge ||
			state.contactEmail !== race.contactEmail ||
			state.snapchatUsername !== race.snapchatUsername ||
			this.urlPrefix(state.facebookUrl) !== race.facebookUrl ||
			this.urlPrefix(state.instagramUrl) !== race.instagramUrl ||
			this.urlPrefix(state.youtubeUrl) !== race.youtubeUrl ||
			this.urlPrefix(state.url) !== race.url ||
			this.urlPrefix(state.registrationUrl) !== race.registrationUrl ||
			parseInt(state.minDistance) !== race.minDistance ||
			state.geographic !== race.geographic ||
			parseInt(state.participants) !== race.participants ||
			state.startDate !== race.startDate ||
			state.endDate !== race.endDate ||
			state.swimmingRequired !== race.swimmingRequired ||
			state.period !== race.period ||
			state.shortDescription !== race.shortDescription ||
			state.description !== race.description ||
			JSON.stringify(state.facts) !== JSON.stringify(this.decode(race.facts)) ||
			JSON.stringify(state.competitionClasses) !==
				JSON.stringify(this.decode(race.competitionClasses)) ||
			state.hexColor !== race.hexColor ||
			state.coverImage !== race.coverImage ||
			state.featuredImages !== race.featuredImages ||
			state.logo !== race.logo
		);
	}

	handleSubmit = (e) => {
		e.preventDefault();
		if (this.anyChanges()) {
			if (this.validate()) {
				this.handleUpdate();
			}
		} else {
			this.setInitState();
			this.setFormEditable(false);
		}
	};

	handleUpdate = () => {
		const race = this.props.race.race;
		const state = this.state;
		let body = {
			members: race.members,
		};
		body.minSquadSize = state.minSquadSize;
		body.maxSquadSize = state.maxSquadSize;
		body.minAge = state.minAge;
		body.maxAge = state.maxAge;
		body.contactEmail = state.contactEmail;
		body.snapchatUsername = state.snapchatUsername;
		body.facebookUrl = this.urlPrefix(state.facebookUrl);
		body.instagramUrl = this.urlPrefix(state.instagramUrl);
		body.youtubeUrl = this.urlPrefix(state.youtubeUrl);
		body.url = this.urlPrefix(state.url);
		body.registrationUrl = this.urlPrefix(state.registrationUrl);
		body.minDistance = state.minDistance;
		body.geographic = state.geographic;
		body.participants = state.participants;
		body.startDate = state.startDate;
		body.endDate = state.endDate;
		body.swimmingRequired = state.swimmingRequired;
		body.period = state.period;
		body.shortDescription = state.shortDescription;
		body.description = state.description;
		body.facts = this.encode(state.facts);
		body.competitionClasses = this.encode(state.competitionClasses);
		body.hexColor = state.hexColor;
		body.coverImage = state.coverImage;
		body.featuredImages = state.featuredImages;
		body.logo = state.logo;

		this.props.updateRace(body, race.id).then((res) => {
			this.props.getRaceByID(state.currentRaceId).then((res) => {
				this.setInitState();
				this.setFormEditable(false);
			});
		});
	};

	catchValidationError = (heading, msg, variant) => {
		this.setState({
			validErrorHeading: heading,
			validErrorMsg: msg,
			validErrorVariant: variant,
		});
	};

	validate() {
		const state = this.state;

		// EMAIL
		const emailRegex =
			"^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$";
		if (
			!RegExp(emailRegex).test(state.contactEmail) &&
			this.state.contactEmail !== "" &&
			this.state.contactEmail !== null
		) {
			this.catchValidationError(
				"Kontakt email",
				"Email format er ikke korrekt",
				color.DANGER
			);
			return false;
		}

		// DATE
		if (
			state.startDate !== "" &&
			state.endDate !== "" &&
			Date.parse(state.startDate) > Date.parse(state.endDate)
		) {
			this.catchValidationError(
				"Løbs Dato",
				"Start dato skal være før slut dato",
				color.DANGER
			);
			return false;
		}

		// May Not Leave Empty

		// LOGO
		// COVER IMAGE

		// Short Description
		if (state.shortDescription === "") {
			this.catchValidationError(
				"Kort beskrivelse",
				"Må ikke være tom",
				color.DANGER
			);
			return false;
		}

		// Description
		if (state.description === "") {
			this.catchValidationError(
				"Beskrivelse",
				"Må ikke være tom",
				color.DANGER
			);
			return false;
		}

		// Geographic
		if (state.geographic === "") {
			this.catchValidationError("Geografi", "Må ikke være tom", color.DANGER);
			return false;
		}

		// Min Squad Size
		if (state.minSquadSize === "") {
			this.catchValidationError(
				"Minium patrulje størelse",
				"Må ikke være tom",
				color.DANGER
			);
			return false;
		}

		// Max Squad Size
		if (state.maxSquadSize === "") {
			this.catchValidationError(
				"Maximum patrulje størelse",
				"Må ikke være tom",
				color.DANGER
			);
			return false;
		}

		// Period
		if (state.period === "") {
			this.catchValidationError("Periode", "Må ikke være tom", color.DANGER);
			return false;
		}

		// Min Age
		if (state.minAge === "") {
			this.catchValidationError(
				"Miniums alder",
				"Må ikke være tom",
				color.DANGER
			);
			return false;
		}

		return true;
	}

	urlPrefix = (url) => {
		const newUrl = url.toLowerCase();
		if (newUrl === "") {
			return newUrl;
		}
		if (!newUrl.startsWith("https://")) {
			const urlSplip = newUrl.split("//");
			return `https://${urlSplip.length > 1 ? urlSplip[1] : urlSplip[0]}`;
		}
		return newUrl;
	};

	onCoverImageRemove = (files) => {
		this.setState({
			coverImage: files,
		});
	};

	onFeaturedImagesRemove = (files) => {
		this.setState({
			featuredImages: files,
		});
	};

	onLogoRemove = (files) => {
		this.setState({
			logo: files,
		});
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onCheckboxChange = (e) => {
		this.setState({ [e.target.name]: !this.state.swimmingRequired });
	};

	onFaqChange = (e) => {
		const index = parseInt(e.target.name);
		let faq = this.state.facts;
		faq[index] = e.target.value;
		this.setState({ facts: faq });
	};

	onCompClassChange = (e) => {
		const index = parseInt(e.target.name);
		let CompClass = this.state.competitionClasses;
		CompClass[index] = e.target.value;
		this.setState({ competitionClasses: CompClass });
	};

	isFactHidden = (value) => {
		if (this.state.editable || (!this.state.editable && value !== "")) {
			return false;
		} else {
			return true;
		}
	};

	render() {
		const { race } = this.props.race;
		const isEditable = this.state.editable;
		let facts = this.state.facts;
		let competitionClasses = this.state.competitionClasses;

		const topBar = (
			<>
				<Row>
					<Col>
						<DropdownButton
							style={{ textAlign: "left" }}
							id="dropdown-basic-button"
							title={this.state.currentRace}
						>
							{this.state.myRacesList.map((race, index) => {
								return (
									<Dropdown.Item
										key={index}
										eventKey={race.name.key}
										onClick={(e) => this.selectRace(e.target.textContent)}
									>
										{race.name}
									</Dropdown.Item>
								);
							})}
						</DropdownButton>
					</Col>
					<Col style={{ textAlign: "right" }}>
						{this.state.editable ? (
							<>
								<Button
									className="me-2"
									variant={outline.DANGER}
									onClick={() => this.setFormEditable(false)}
									autoFocus={false}
								>
									Annuller
								</Button>{" "}
								<Button variant={outline.SUCCESS} onClick={this.handleSubmit}>
									Gem
								</Button>
							</>
						) : (
							<Button
								variant={outline.PRIMARY}
								onClick={() => this.setFormEditable(true)}
							>
								Rediger
							</Button>
						)}
					</Col>
				</Row>
			</>
		);

		const factsFragment = (
			<>
				<Row>
					{facts.map((faq, index) => {
						return (
							<Row>
								<Col>
									<InputGroup className="mb-3">
										{faq !== "" || isEditable ? (
											<InputGroup.Text id="basic-addon1">
												{" "}
												{index + 1}.{" "}
											</InputGroup.Text>
										) : null}
										<Form.Control
											placeholder={race && !isEditable ? faq : ""}
											name={index}
											onChange={this.onFaqChange}
											type="text"
											value={!isEditable ? "" : facts[index]}
											disabled={!isEditable}
											hidden={this.isFactHidden(facts[index])}
										/>
									</InputGroup>
								</Col>
							</Row>
						);
					})}
				</Row>
			</>
		);

		const competitionFragment = (
			<>
				<Row>
					{competitionClasses.map((CompClass, index) => {
						return (
							<Row>
								<Col>
									<InputGroup className="mb-3">
										{CompClass !== "" || isEditable ? (
											<InputGroup.Text id="basic-addon1">
												{" "}
												{index + 1}.{" "}
											</InputGroup.Text>
										) : null}
										<Form.Control
											placeholder={race && !isEditable ? CompClass : ""}
											name={index}
											onChange={this.onCompClassChange}
											type="text"
											value={!isEditable ? "" : competitionClasses[index]}
											disabled={!isEditable}
											hidden={this.isFactHidden(competitionClasses[index])}
										/>
									</InputGroup>
								</Col>
							</Row>
						);
					})}
				</Row>
			</>
		);

		const raceForm = (
			<>
				<h1>{race ? race.name : ""}</h1>
				<Form>
					{this.state.validErrorMsg !== null ? (
						<Alert variant={this.state.validErrorVariant}>
							{" "}
							<Alert.Heading>{this.state.validErrorHeading}</Alert.Heading>{" "}
							{this.state.validErrorMsg}
						</Alert>
					) : (
						""
					)}
					<Row>
						<Col lg={6}>
							<Form.Label>Kort beskrivelse</Form.Label>
							<FormGroup className="mb-3">
								<Form.Control
									placeholder={race && !isEditable ? race.shortDescription : ""}
									name="shortDescription"
									onChange={this.onChange}
									as="textarea"
									style={{ height: "50px" }}
									value={this.state.shortDescription}
									disabled={!isEditable}
								/>
							</FormGroup>
						</Col>
						<Col lg={6}>
							<Form.Label>Periode</Form.Label>
							<FormGroup className="mb-3">
								<Form.Control
									placeholder={race && !isEditable ? race.period : ""}
									name="period"
									onChange={this.onChange}
									type="text"
									value={this.state.period}
									disabled={!isEditable}
								/>
							</FormGroup>
						</Col>

						<Col lg={6}>
							<Form.Label>Minium patrulje størelse</Form.Label>
							<FormGroup className="mb-3">
								<Form.Control
									placeholder={race && !isEditable ? race.minSquadSize : ""}
									name="minSquadSize"
									onChange={this.onChange}
									type="number"
									value={this.state.minSquadSize}
									disabled={!isEditable}
								/>
							</FormGroup>
						</Col>
						<Col lg={6}>
							<Form.Label>Maximum patrulje størelse</Form.Label>
							<FormGroup>
								<Form.Control
									placeholder={race && !isEditable ? race.maxSquadSize : ""}
									name="maxSquadSize"
									onChange={this.onChange}
									type="number"
									value={this.state.maxSquadSize}
									disabled={!isEditable}
								/>
							</FormGroup>
						</Col>

						<Col lg={6}>
							<Form.Label>Miniums alder</Form.Label>
							<FormGroup className="mb-3">
								<Form.Control
									placeholder={race && !isEditable ? race.minAge : ""}
									name="minAge"
									onChange={this.onChange}
									type="number"
									value={this.state.minAge}
									disabled={!isEditable}
								/>
							</FormGroup>
						</Col>
						<Col lg={6}>
							<Form.Label>Maxium alder</Form.Label>
							<FormGroup className="mb-3">
								<Form.Control
									placeholder={race && !isEditable ? race.maxAge : ""}
									name="maxAge"
									onChange={this.onChange}
									type="number"
									value={this.state.maxAge}
									disabled={!isEditable}
								/>
							</FormGroup>
						</Col>

						<Col lg={6}>
							<Form.Label>Løbets kontakt Email</Form.Label>
							<FormGroup className="mb-3">
								<Form.Control
									placeholder={race && !isEditable ? race.contactEmail : ""}
									name="contactEmail"
									onChange={this.onChange}
									type="email"
									value={this.state.contactEmail}
									disabled={!isEditable}
								/>
							</FormGroup>
						</Col>
						<Col lg={6}>
							<Form.Label>Snapchat brugernavn</Form.Label>
							<FormGroup className="mb-3">
								<Form.Control
									placeholder={race && !isEditable ? race.snapchatUsername : ""}
									name="snapchatUsername"
									onChange={this.onChange}
									type="text"
									value={this.state.snapchatUsername}
									disabled={!isEditable}
								/>
							</FormGroup>
						</Col>

						<Col lg={6}>
							<Form.Label>Facebook link</Form.Label>
							<InputGroup className="mb-3">
								<InputGroup.Text id="basic-addon1">https://</InputGroup.Text>
								<Form.Control
									placeholder={
										race && !isEditable && race.facebookUrl
											? race.facebookUrl.split("//")[1]
											: ""
									}
									name="facebookUrl"
									onChange={this.onChange}
									type="text"
									value={this.state.facebookUrl}
									disabled={!isEditable}
								/>
							</InputGroup>
						</Col>
						<Col lg={6}>
							<Form.Label>Instagram link</Form.Label>
							<InputGroup className="mb-3">
								<InputGroup.Text id="basic-addon1">https://</InputGroup.Text>
								<Form.Control
									placeholder={
										race && !isEditable && race.instagramUrl
											? race.instagramUrl.split("//")[1]
											: ""
									}
									name="instagramUrl"
									onChange={this.onChange}
									type="text"
									value={this.state.instagramUrl}
									disabled={!isEditable}
								/>
							</InputGroup>
						</Col>

						<Col lg={6}>
							<Form.Label>Youtube link</Form.Label>
							<InputGroup className="mb-3">
								<InputGroup.Text id="basic-addon1">https://</InputGroup.Text>
								<Form.Control
									placeholder={
										race && !isEditable && race.youtubeUrl
											? race.youtubeUrl.split("//")[1]
											: ""
									}
									name="youtubeUrl"
									onChange={this.onChange}
									type="text"
									value={this.state.youtubeUrl}
									disabled={!isEditable}
								/>
							</InputGroup>
						</Col>
						<Col lg={6}>
							<Form.Label>Hjemmeside link</Form.Label>
							<InputGroup className="mb-3">
								<InputGroup.Text id="basic-addon1">https://</InputGroup.Text>
								<Form.Control
									placeholder={
										race && !isEditable && race.url
											? race.url.split("//")[1]
											: ""
									}
									name="url"
									onChange={this.onChange}
									type="text"
									value={this.state.url}
									disabled={!isEditable}
								/>
							</InputGroup>
						</Col>

						<Col lg={6}>
							<Form.Label>Link til tilmelding</Form.Label>
							<InputGroup className="mb-3">
								<InputGroup.Text id="basic-addon1">https://</InputGroup.Text>
								<Form.Control
									placeholder={
										race && !isEditable && race.registrationUrl
											? race.registrationUrl.split("//")[1]
											: ""
									}
									name="registrationUrl"
									onChange={this.onChange}
									type="text"
									value={this.state.registrationUrl}
									disabled={!isEditable}
								/>
							</InputGroup>
						</Col>
						<Col lg={6}>
							<Form.Label>Distance</Form.Label>
							<InputGroup className="mb-3">
								<InputGroup.Text id="basic-addon1">Ca.</InputGroup.Text>
								<Form.Control
									placeholder={race && !isEditable ? race.minDistance : ""}
									name="minDistance"
									onChange={this.onChange}
									type="number"
									value={this.state.minDistance}
									disabled={!isEditable}
								/>
							</InputGroup>
						</Col>

						<Col lg={6}>
							<Form.Label>Løbets geografiske område</Form.Label>
							<FormGroup className="mb-3">
								<Form.Control
									placeholder={race && !isEditable ? race.geographic : ""}
									name="geographic"
									onChange={this.onChange}
									type="text"
									value={this.state.geographic}
									disabled={!isEditable}
								/>
							</FormGroup>
						</Col>
						<Col lg={6}>
							<Form.Label>Max antal deltagere</Form.Label>
							<FormGroup className="mb-3">
								<Form.Control
									placeholder={race && !isEditable ? race.participants : ""}
									name="participants"
									onChange={this.onChange}
									type="number"
									value={this.state.participants}
									disabled={!isEditable}
								/>
							</FormGroup>
						</Col>
						<Col lg={6}>
							<Form.Label>Start dato</Form.Label>
							<FormGroup className="mb-3">
								<Form.Control
									name="startDate"
									onChange={this.onChange}
									type="date"
									value={this.state.startDate}
									disabled={!isEditable}
								/>
							</FormGroup>
						</Col>
						<Col lg={6}>
							<Form.Label>Slut dato</Form.Label>
							<FormGroup>
								<Form.Control
									name="endDate"
									onChange={this.onChange}
									type="date"
									value={this.state.endDate}
									disabled={!isEditable}
								/>
							</FormGroup>
						</Col>
						<Col lg={6}>
							<Form.Label>Udvalgte billeder fra løbet</Form.Label>
							<Row>
								{race ? (
									<ImagesViewItem
										readonly={!isEditable}
										max={5}
										value={this.state.featuredImages}
										onChange={this.onFeaturedImagesRemove}
									/>
								) : null}
							</Row>
						</Col>
						<Col lg={6}>
							<Form.Label>Løbs farve</Form.Label>
							<InputGroup className="mb-3">
								<Form.Control
									name="hexColor"
									onChange={this.onChange}
									type="color"
									value={this.state.hexColor}
									disabled={!isEditable}
								/>
							</InputGroup>
						</Col>

						<Col lg={6}>
							<Form.Label>Facts om løbet</Form.Label>
							{(race && isEditable) ||
							(race && race.facts != null && race.facts !== "") ? (
								factsFragment
							) : (
								<p>Dit løb har endnu ingen facts</p>
							)}
						</Col>
						<Col lg={6}>
							<Form.Label>konkurrence klasser</Form.Label>
							{(race && isEditable) ||
							(race &&
								race.competitionClasses != null &&
								race.competitionClasses !== "") ? (
								competitionFragment
							) : (
								<p>Dit løb har endnu ingen konkurrence klasser</p>
							)}
						</Col>

						<Col lg={6}>
							<Form.Label>Logo</Form.Label>
							{race ? (
								<ImagesViewItem
									readonly={!isEditable}
									max={1}
									value={this.state.logo}
									onChange={this.onLogoRemove}
								/>
							) : null}
						</Col>
						<Col lg={6}>
							<Form.Label>Cover billede</Form.Label>
							{race ? (
								<ImagesViewItem
									readonly={!isEditable}
									max={1}
									value={this.state.coverImage}
									onChange={this.onCoverImageRemove}
								/>
							) : null}
						</Col>

						<Col lg={6}>
							<Form.Label>Beskrivelse</Form.Label>
							<FormGroup className="mb-3">
								<Form.Control
									placeholder={race && !isEditable ? race.description : ""}
									name="description"
									onChange={this.onChange}
									as="textarea"
									style={{ height: "200px" }}
									value={this.state.description}
									disabled={!isEditable}
								/>
							</FormGroup>
						</Col>
						<Col lg={6}>
							<Form.Label>Krav om svømmeevner</Form.Label>
							<FormGroup className="mb-3">
								<Form.Check
									checked={this.state.swimmingRequired}
									name="swimmingRequired"
									onChange={this.onCheckboxChange}
									type="switch"
									value={this.state.swimmingRequired}
									disabled={!isEditable}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</>
		);

		return (
			<>
				{topBar}
				<Row className="mb-5">
					<Col>{race ? raceForm : <h1>Henter løb</h1>}</Col>
				</Row>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	race: state.race,
});

export default connect(mapStateToProps, {
	getRacesByMember,
	getRaceByID,
	updateRace,
})(MyRaces);
