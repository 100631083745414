//AUTH

//AUTH
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CHANGE_PASSWORD_EMAIL_SEND_SUCCESS =
  "CHANGE_PASSWORD_EMAIL_SEND_SUCCESS";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

//UPDATE USER INFO
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const GET_PUBLIC_USERS = "GET_PUBLIC_USERS";

//CHANGE PASSWORD
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

//DELETE USER
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

// Race
export const RACE_LOADING = "RACE_LOADING";
export const GET_RACES = "GET_RACES";
export const GET_RACE = "GET_RACE";
export const GET_MY_RACE_LIST = "GET_MY_RACE_LIST";
export const RACE_UPDATE_FAIL = "RACE_UPDATE_FAIL";
export const GET_RACE_BY_NAME = "GET_RACE_BY_NAME";

// FAQ
export const FAQ_LOADING = "FAQ_LOADING";
export const GET_FAQ = "GET_FAQ";

// SQUAD
export const GET_MY_SQUADS_LIST = "GET_MY_SQUADS_LIST";
export const CRECATE_SEASON_ID = "CRECATE_SEASON_ID";
export const CRECATE_SQUAD = "CRECATE_SQUAD";

// SEASON
export const GET_MY_OPEN_SQUADS_LIST = "GET_MY_OPEN_SQUADS_LIST";
export const GET_SEASONS = "GET_SEASONS";

// POINTS
export const GET_ALL_SCORE = "GET_ALL_SCORE";
