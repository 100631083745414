import {
  RACE_LOADING,
  GET_RACES,
  GET_RACE,
  GET_MY_RACE_LIST,
  GET_RACE_BY_NAME,
} from "../actions/types";

const initialState = {
  races: [],
  loading: false,
  race: null,
  myRaceList: [],
};

const raceReducer = (state = initialState, action) => {
  switch (action.type) {
    case RACE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_RACES:
      return {
        ...state,
        races: action.payload,
        loading: false,
      };
    case GET_RACE:
      return {
        ...state,
        race: action.payload,
        loading: false,
      };
    case GET_MY_RACE_LIST:
      return {
        ...state,
        myRaceList: action.payload,
      };

    case GET_RACE_BY_NAME:
      return {
        ...state,
        race: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default raceReducer;
