import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Table,
  Badge,
  Modal,
} from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSeasons } from "../../actions/seasonActions";
import { getAllScore } from "../../actions/scoreActions";
import { getPublicUsersByIDs } from "../../actions/userActions";
import {
  getCurrentSeason,
  sortSeasonsByStartDate,
  structureScores,
  setIcon,
} from "../utilities/PointsOverviewUtilites";
import { SwapSpinner } from "react-spinners-kit";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PointsOverview extends Component {
  state = {
    seasons: [],
    currentSeason: {},
    pointsForTable: {},
    scores: [],
    showSquadModal: false,
    currentSquad: {},
    squadMembers: [],
  };

  static propTypes = {
    getSeasons: PropTypes.func.isRequired,
    getAllScore: PropTypes.func.isRequired,
    getPublicUsersByIDs: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const seasons = this.props.getSeasons();
    seasons.then((seasonRes) => {
      this.setState({
        seasons: sortSeasonsByStartDate(seasonRes.payload.rows),
        currentSeason: getCurrentSeason(seasonRes.payload.rows),
      });
      const scores = this.props.getAllScore();
      scores.then((scoreRes) => {
        this.setState({
          scores: scoreRes.payload.rows,
          pointsForTable: structureScores(
            scoreRes.payload.rows,
            this.state.currentSeason
          ),
        });
      });
    });
  };

  isJsonEmpty = (json) => {
    return JSON.stringify(json) === JSON.stringify({});
  };

  selectSeason = (season) => {
    if (season !== this.state.currentSeason) {
      this.setState({
        currentSeason: season,
        pointsForTable: structureScores(this.state.scores, season),
      });
    }
  };

  render() {
    const pointsForTable = this.state.pointsForTable;
    const logos = this.state.pointsForTable.logos;
    const squads = this.state.pointsForTable.squads;
    const currentSquad = this.state.currentSquad;
    const squadMembers = this.state.squadMembers;

    const loading = (
      <Row className="pt-5 mt-5 d-flex justify-content-center">
        <SwapSpinner size={75} />
      </Row>
    );

    const dropdown = (
      <>
        <DropdownButton
          id="dropdown-basic-button"
          title={this.state.currentSeason.name + " "}
          className="dropdown-btn"
          style={{ textAlign: "left" }}
        >
          {this.state.seasons.map((season, index) => {
            return (
              <Dropdown.Item
                key={index}
                eventKey={season.name}
                onClick={(e) => this.selectSeason(season)}
              >
                {season.name}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
      </>
    );

    const getHeaderRow = (
      <>
        {logos && logos.length > 0 ? (
          <>
            <th></th>
            <th style={{ textAlign: "left" }}>Patrulje</th>
          </>
        ) : null}
        {logos
          ? logos.map((logo, index) => {
              return (
                <th key={index}>
                  {/* <img
										src={logo[1]}
										alt={logo[0]}
										style={{ maxWidth: "175px", maxHeight: "50px" }}
									/>
									<br /> */}
                  {logo[0]}
                </th>
              );
            })
          : null}
        {logos && logos.length > 0 ? <th>Total</th> : null}
      </>
    );

    const getBodyRows = (
      <>
        {squads
          ? Object.keys(squads).map((squad, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {index + 1}.
                  </td>
                  <td
                    className=""
                    style={{
                      textAlign: "left",
                      width: "1%",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() =>
                      this.props
                        .getPublicUsersByIDs(squads[squad].squad.member)
                        .then((res) => {
                          this.setState({
                            showSquadModal: true,
                            currentSquad: squads[squad].squad,
                            squadMembers: res.payload,
                          });
                        })
                    }
                  >
                    <Badge
                      className="badge-medium me-2"
                      bg="danger"
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon icon={faUsers} className="" />
                    </Badge>
                    {squad}
                  </td>
                  {Object.values(squads[squad].points).map((point, index) => {
                    if (point === null) {
                      return <td key={index}>-</td>;
                    } else {
                      return <td key={index}>{point}</td>;
                    }
                  })}
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {squads[squad].total + " "} {setIcon(index)}
                  </td>
                </tr>
              );
            })
          : null}
      </>
    );

    const pointTable = (
      <>
        {!this.isJsonEmpty(Object.values(this.state.pointsForTable)[1]) ? (
          <Table hover responsive striped>
            <thead>
              <tr>{getHeaderRow}</tr>
            </thead>
            <tbody>{getBodyRows}</tbody>
          </Table>
        ) : (
          <p>Der er endnu ikke givet nogle point i denne sæson.</p>
        )}
      </>
    );

    const squadModal = (
      <Modal
        show={this.state.showSquadModal}
        onHide={() => this.setState({ showSquadModal: false })}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="mt-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>{currentSquad.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Patrulje medlemmer</h5>
          {currentSquad.member
            ? squadMembers[0].map((member, index) => {
                return (
                  <li className="ms-1" key={index}>
                    {member.fullName}
                  </li>
                );
              })
            : null}
        </Modal.Body>
      </Modal>
    );

    return (
      <Container>
        <Row className="mb-3">
          <Col xs="12">
            <h1 className="page-title">Pointoversigt</h1>
          </Col>
          <Col xs="12">{dropdown}</Col>
        </Row>
        <Row>
          <Col xs="12">{pointsForTable ? pointTable : loading}</Col>
        </Row>
        {squadModal}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  getSeasons,
  getAllScore,
  getPublicUsersByIDs,
})(PointsOverview);
