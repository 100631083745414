import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

class Terms extends Component {
	componentDidMount() {}

	render() {
		return (
			<Container>
				<Row>
					<Col xs="12">
						<h1 className="page-title">
							Vilkår og betingelser for brug af Adventurespejd.dk
						</h1>
					</Col>
					<Col xs="12">
						<p className="text-lg-start">
							Du acceptere hermed at Adventurespejd.dk må håndtere dine
							oplysninger, må modtage oplysninger om din deltagelse på løb fra
							løbene, og vise dit fulde navn i oversigten over patruljer.
							Derudover bekræfter du at overholder{" "}
							<Link to="/liga">alle gældende egler</Link>
						</p>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default Terms;
