import axios from "axios";
import { returnErrors } from "./errorActions";
import {
	USER_LOADED,
	USER_LOADING,
	AUTH_ERROR,
	LOGIN_FAIL,
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS,
	REGISTER_FAIL,
	REGISTER_SUCCESS,
	CHANGE_PASSWORD_SUCCESS,
	DELETE_USER_SUCCESS,
	CHANGE_PASSWORD_EMAIL_SEND_SUCCESS,
	RESET_PASSWORD_SUCCESS,
} from "./types";

const baseURL = process.env.REACT_APP_BASEURL;
const tennent = process.env.REACT_APP_TENANT;

// Check token and load user
export const loadUser = () => (dispatch, getState) => {
	// User Loading
	dispatch({ type: USER_LOADING });

	// Get user from DB
	axios
		.get(baseURL + "/auth/me", setHeaders(getState))
		.then((res) =>
			dispatch({
				type: USER_LOADED,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch({
				type: AUTH_ERROR,
			});
		});
};

export const reLoadUser = () => (dispatch, getState) => {
	// Get user from DB
	axios
		.get(baseURL + "/auth/me", setHeaders(getState))
		.then((res) =>
			dispatch({
				type: USER_LOADED,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch({
				type: AUTH_ERROR,
			});
		});
};

// Register User
export const register =
	({ firstName, lastName, email, password }) =>
	(dispatch) => {
		const config = {
			headers: {
				"Content-type": "application/json",
			},
		};

		//Body
		const body = JSON.stringify({ firstName, lastName, email, password });

		axios
			.post(baseURL + "/auth/sign-up", body, config)
			.then((res) => {
				// Rename data to token
				res["token"] = res.data;
				delete res.data;

				dispatch({
					type: REGISTER_SUCCESS,
					payload: res,
				});
			})
			.catch((err) => {
				dispatch(
					returnErrors(err.response.data, err.response.status, "REGISTER_FAIL")
				);
				dispatch({
					type: REGISTER_FAIL,
				});
			});
	};

// Login User
export const login =
	({ email, password }) =>
	(dispatch) => {
		const config = {
			headers: {
				"Content-type": "application/json",
			},
		};

		//Body
		const body = JSON.stringify({ email, password });

		axios
			.post(baseURL + "/auth/sign-in", body, config)
			.then((res) => {
				// Rename data to token
				res["token"] = res.data;
				delete res.data;

				dispatch({
					type: LOGIN_SUCCESS,
					payload: res,
				});
			})
			.catch((err) => {
				dispatch(
					returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
				);
				dispatch({
					type: LOGIN_FAIL,
				});
			});
	};

// Logout
export const logout = () => {
	return {
		type: LOGOUT_SUCCESS,
	};
};

// Update User
export const updateUser = (newInfo) => async (dispatch, getState) => {
	const body = { data: newInfo };
	try {
		return await axios.put(
			baseURL + "/auth/profile",
			body,
			setHeaders(getState)
		);
	} catch (err) {
		dispatch(
			returnErrors(err.response.data, err.response.status, "USER_UPDATE_FAIL")
		);
	}
};

export const changePassword = (password) => (dispatch, getState) => {
	axios
		.put(baseURL + "/auth/change-password", password, setHeaders(getState))
		.then((res) => {
			dispatch({
				type: CHANGE_PASSWORD_SUCCESS,
				payload: res,
			});
		})
		.catch((err) => {
			dispatch(
				returnErrors(
					err.response.data,
					err.response.status,
					"CHANGE_PASSWORD_FAIL"
				)
			);
		});
};

export const deleteUser = (userEmail) => (dispatch, getState) => {
	axios
		.delete(baseURL + "auth/delete-user", userEmail, setHeaders(getState))
		.then((res) => {
			dispatch({
				type: DELETE_USER_SUCCESS,
				payload: res,
			});
		})
		.catch((err) => {
			dispatch(
				returnErrors(
					err.response.data,
					err.response.status,
					"DELETE_USER_SUCCESS"
				)
			);
		});
};

export const sendPasswordResetEmail = (email) => (dispatch, getState) => {
	const body = { email: email, tenantId: tennent };
	axios
		.post(
			baseURL + "/auth/send-password-reset-email",
			body,
			setHeaders(getState)
		)
		.then((res) => {
			dispatch({
				type: CHANGE_PASSWORD_EMAIL_SEND_SUCCESS,
				payload: res,
			});
		})
		.catch((err) => {
			dispatch(
				returnErrors(
					err.response.data,
					err.response.status,
					"CHANGE_PASSWORD_EMAIL_SEND_FAIL"
				)
			);
		});
};

export const resetPassword =
	(password, token) => async (dispatch, getState) => {
		const body = { password: password, token: token };
		return await axios
			.put(baseURL + "/auth/password-reset", body, setHeaders(getState))
			.then((res) => {
				dispatch({
					type: RESET_PASSWORD_SUCCESS,
					payload: res,
				});
			})
			.catch((err) => {
				dispatch(
					returnErrors(
						err.response.data,
						err.response.status,
						"RESET_PASSWORD_FAIL"
					)
				);
			});
	};

// Config headers and token
const setHeaders = (getState) => {
	// Get token from localstorage
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json",
		},
	};
	// If token, add to headers
	if (token) {
		config.headers["Authorization"] = "Bearer " + token;
	}
	return config;
};
