import React, { Component, Fragment } from "react";
import SquadTable from "./mySquadsContent/SquatTabel";

class MySquad extends Component {
  render() {
    return (
      <Fragment>
        <SquadTable />
      </Fragment>
    );
  }
}
export default MySquad;
