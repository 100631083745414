import { Component } from "react";
import MyRaces from "./MyRaces";
import MySettings from "./MySettings";
import MySquad from "./MySquad";
import { Tabs, Tab, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRacesByMember } from "../../actions/raceActions";

class MyProfile extends Component {
  state = {
    myRacesList: [],
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    getRacesByMember: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const tempRaceList = this.props.getRacesByMember(this.props.auth.user.id);
    tempRaceList.then((res) => {
      this.setState({
        myRacesList: res.payload,
      });
    });
  }

  render() {
    return (
      <>
        <Container>
          <Tabs
            defaultActiveKey="settings"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="settings" title="Min Konto">
              <MySettings />
            </Tab>
            <Tab eventKey="squads" title="Mine patruljer">
              <MySquad />
            </Tab>
            {this.state.myRacesList.length > 0 ? (
              <Tab eventKey="races" title="Mine løb">
                <MyRaces />
              </Tab>
            ) : null}
          </Tabs>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getRacesByMember,
})(MyProfile);
