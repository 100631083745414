import { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  Table,
  Modal,
  Row,
  Col,
  FormGroup,
  Form,
  Alert,
  Badge,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  getMySquads,
  cretateLeagueID,
  createNewSquad,
  addMembersToSquad,
} from "../../../actions/squadActions";
import { getMyOpenSeasons } from "../../../actions/seasonActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const plusIcon = <FontAwesomeIcon className="" icon={faPlus} />;
const maximumSquadSize = 8;
class SquadTable extends Component {
  state = {
    mySquadsList: [],
    openSeasons: [],
    showSquadModal: false,
    showAddMemberModal: false,
    seasonID: "",
    squadName: "",
    ageGroup: "12-17",
    userID: this.props.auth.user.id,
    amountOfMembers: 0,
    newMembers: [],
    selectedSquad: {},
    msg: null,
  };

  handelShowSquadModal = () => {
    this.setState({ showSquadModal: true });
  };
  handelCloseSquadModal = () => {
    this.setState({ showSquadModal: false });
  };

  handelShowAddMemberModal = () => {
    this.setState({ showAddMemberModal: true });
  };

  handelCloseAddMemberModal = () => {
    this.setState({ showAddMemberModal: false });
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    getMySquads: PropTypes.func.isRequired,
    getMyOpenSeasons: PropTypes.func.isRequired,
    cretateLeagueID: PropTypes.func.isRequired,
    createNewSquad: PropTypes.func.isRequired,
    addMembersToSquad: PropTypes.func.isRequired,
    error: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const mySquads = this.props.getMySquads();
    mySquads.then((res) => {
      this.setState({
        mySquadsList: res.payload,
      });
    });
    const myOpenSesons = this.props.getMyOpenSeasons();
    myOpenSesons.then((res) => {
      this.setState({
        openSeasons: res.payload,
      });
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  createNewSquad = () => {
    const { squadName, userID, ageGroup, seasonID, seasonName } = this.state;

    this.props.cretateLeagueID({ seasonName, ageGroup }).then((res) => {
      const newSquad = {
        name: squadName,
        member: [userID],
        ageGroup,
        leagueID: res.payload.ligaID,
        season: seasonID,
      };
      this.props.createNewSquad(newSquad).then((res) => {
        if (res) {
          this.getData();
          this.handelCloseSquadModal();
        } else {
          const { errors } = this.props;
          if (errors.id === "CREATE_SQUAD_ERROR") {
            this.setState({ msg: errors.msg });
          }
        }
      });
    });
  };

  addMembers = () => {
    const { newMembers, selectedSquad, seasonID } = this.state;
    const squadID = selectedSquad.id;

    const payload = {
      member: newMembers,
      season: seasonID,
    };
    this.props.addMembersToSquad(payload, squadID).then((res) => {
      if (res) {
        this.setState({ msg: null });
        toast.success(
          res.data.success.length > 0
            ? `${res.data.success.join(", ")} er nu tilknyttet ${
                selectedSquad.name
              }`
            : null
        );
        toast.warning(
          res.data.notFoundMembersEmail.length > 0
            ? `${res.data.notFoundMembersEmail.join(
                ", "
              )} er ikke registrerede brugere`
            : null
        );
        toast.info(
          res.data.alreadyMemberEmails.length > 0
            ? `${res.data.alreadyMemberEmails.join(
                ", "
              )} er i forvejen tilknyttet ${selectedSquad.name}`
            : null
        );

        this.getData();
        this.handelCloseAddMemberModal();
        this.setState({ newMembers: [] });
      } else {
        const { errors } = this.props;
        if (errors.id === "MXMUMUM_SQUAD_ERROR") {
          this.setState({ msg: errors.msg });
        }
      }
    });
  };

  isAddMembersDisabled = () => {
    return (
      this.state.newMembers.length + this.state.amountOfMembers >
        maximumSquadSize || this.state.newMembers.length === 0
    );
  };

  render() {
    let squads = this.state.mySquadsList;
    const { showSquadModal, showAddMemberModal, newMembers } = this.state;

    const squadRows = (
      <>
        {squads.map((squad, index) => {
          if (squad.season) {
            //Failsave if user is member of a squad in no season
            return (
              <tr key={index}>
                <td>{squad.season.name}</td>
                <td>{squad.name}</td>
                <td>{squad.leagueID}</td>
                <td>{squad.ageGroup}</td>
                <td>
                  {squad.member.map((member) => (
                    <div>
                      <Badge className="badge-medium mt-1 me-1" bg="info">
                        {member.email}
                      </Badge>
                    </div>
                  ))}
                </td>
                <td>
                  {new Date(squad.season.endDate) > new Date() ? ( //If season is not over
                    squad.member.length < maximumSquadSize ? (
                      <Button
                        variant="info"
                        onClick={() => {
                          this.handelShowAddMemberModal();
                          this.setState({
                            selectedSquad: squad,
                            seasonID: squad.season._id,
                            amountOfMembers: squad.member.length,
                          });
                        }}
                      >
                        {plusIcon}
                      </Button>
                    ) : (
                      "Patrulje fyldt"
                    )
                  ) : (
                    "Sæson er afsluttet"
                  )}
                </td>
              </tr>
            );
          }
          return null;
        })}
        {this.state.openSeasons.length > 0
          ? this.state.openSeasons.map((season, index) => {
              return (
                <tr key={index}>
                  <td>{season.seasonName}</td>
                  <td>
                    <Button
                      onClick={() => {
                        this.handelShowSquadModal();
                        this.setState({ seasonID: season.seasonID });
                        this.setState({ seasonName: season.seasonName });
                      }}
                    >
                      Opret patrulje
                    </Button>
                  </td>
                </tr>
              );
            })
          : null}
      </>
    );

    return (
      <>
        <Card style={{ textAlign: "left" }} className="mb-3">
          <Card.Header>Oversigt</Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>Sæson</th>
                  <th>Patrulje navn</th>
                  <th>LigaID</th>
                  <th>Aldersgruppe</th>
                  <th>Medlemmer</th>
                  <th>Tilføj medlem</th>
                </tr>
              </thead>
              <tbody>{squadRows}</tbody>
            </Table>
          </Card.Body>
        </Card>

        {/* Modal for adding mambers to squad */}
        <Modal
          show={showAddMemberModal}
          onHide={this.handelCloseAddMemberModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Tilføj medlem</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.amountOfMembers + newMembers.length >
            maximumSquadSize ? (
              <Alert variant="danger">{`Der må max være ${maximumSquadSize} medlemmer i en patrulje, der er allerede ${this.state.amountOfMembers} medlemmer og du er ved at tilføje ydderligere ${this.state.newMembers.length}`}</Alert>
            ) : null}
            {this.state.msg ? (
              <Alert variant="danger">{this.state.msg}</Alert>
            ) : null}
            <Form.Group>
              <Form.Label>Indtast medlemmers Emails</Form.Label>
              <ReactMultiEmail
                placeholder="Input your Email Address"
                emails={newMembers}
                onChange={(emails) => {
                  this.setState({ newMembers: emails });
                }}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      {email}
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  );
                }}
              />
              <p style={{ fontSize: 12, color: "red" }}>
                OBS. Der er endnu kun muligt at tilføje medlemmer der allerede
                har oprettet en bruger
              </p>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.handelCloseAddMemberModal}
            >
              Annuler
            </Button>
            <Button
              variant="danger"
              onClick={this.addMembers}
              disabled={this.isAddMembersDisabled()}
            >
              Tilføj
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal for creating new squad */}
        <Modal show={showSquadModal} onHide={this.handelCloseSquadModal}>
          <Modal.Header closeButton>
            <Modal.Title>Opret patrulje</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  {this.state.msg ? (
                    <Alert variant="danger">{this.state.msg}</Alert>
                  ) : null}
                  <FormGroup className="mt-3">
                    <Form.Label>Patrulje navn</Form.Label>
                    <Form.Control
                      name="squadName"
                      onChange={this.handleChange}
                      type="text"
                    />
                  </FormGroup>
                  <FormGroup className="mt-3">
                    <Form.Label>Aldersgruppe</Form.Label>
                    <Form.Control
                      name="ageGroup"
                      onChange={this.handleChange}
                      as="select"
                    >
                      <option>12-17</option>
                    </Form.Control>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handelCloseSquadModal}>
              Annuler
            </Button>
            <Button variant="danger" onClick={this.createNewSquad}>
              Opret patrulje
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer
          position="bottom-left"
          autoClose={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.error,
});

export default connect(mapStateToProps, {
  getMySquads,
  getMyOpenSeasons,
  cretateLeagueID,
  createNewSquad,
  addMembersToSquad,
})(SquadTable);
