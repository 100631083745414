import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
	Container,
	Row,
	Col,
	Carousel,
	Button,
	Badge,
	Modal,
	Ratio,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebook,
	faInstagram,
	faYoutube,
	//faSnapchatGhost, /* Snapchat funktion fjernet indtil vi får endpoint til at virke */
} from "@fortawesome/free-brands-svg-icons";
import {} from "@fortawesome/free-brands-svg-icons";
import tinycolor from "tinycolor2";
import moment from "moment";
import Snapcode from "./utilities/Snapcode";
import { getRaceByName } from "../actions/raceActions";
import ErrorLayout from "./ErrorLayout";
import { SwapSpinner } from "react-spinners-kit";

moment.locale("da");

class RaceProfile extends Component {
	state = {
		show: false,
	};

	componentDidMount() {
		// Get race name from URL
		const { raceName } = this.props.match.params;
		this.props.getRaceByName(raceName);
	}
	render() {
		const { race, loading } = this.props.race;

		const setShow = (value) => {
			this.setState({
				show: value,
			});
		};

		const facebookIcon = (
			<FontAwesomeIcon
				className="fa-3x p-1 align-middle"
				icon={faFacebook}
				color="#1778F2"
			/>
		);
		const instagramIcon = (
			<FontAwesomeIcon
				className="fa-3x p-1 align-middle"
				icon={faInstagram}
				color="#E1306C"
			/>
		);
		const youtubeIcon = (
			<FontAwesomeIcon
				className="fa-3x p-1 align-middle"
				icon={faYoutube}
				color="#ff0000"
			/>
		);
		/*
		 * Snapchat funktion fjernet indtil vi får endpoint til at virke
		 */
		/*const snapcatIcon = (
      <FontAwesomeIcon
        className="fa-3x p-1 align-middle"
        icon={faSnapchatGhost}
        color="#fffc00"
      />
    );*/

		function SoMeIcon(props) {
			return (
				<Col>
					<a
						href={props.url}
						target="_blank"
						rel="noreferrer"
						className="text-decoration-none"
					>
						{props.icon}{" "}
						<span className="text-white h3   align-middle">{props.label}</span>
					</a>
				</Col>
			);
		}

		/*
		 * Snapchat funktion fjernet indtil vi får endpoint til at virke
		 */
		/*function SnapchatIcon(props) {
      return (
        <Col>
          <div onClick={() => setShow(true)} style={{ cursor: "pointer" }}>
            {props.icon}{" "}
            <span className="text-white h3   align-middle">{props.label}</span>
          </div>
        </Col>
      );
    }*/

		function decode(encodedString) {
			let newArray = encodedString !== null ? encodedString.split("|") : [];

			return newArray;
		}

		return (
			<>
				{loading ? (
					<Container>
						<Row className="pt-5 mt-5 d-flex justify-content-center">
							<SwapSpinner size={75}></SwapSpinner>
						</Row>
					</Container>
				) : race ? (
					<Container>
						<Row>
							<h1 className="page-title">
								<Link
									className="text-decoration-none text-primary"
									to={"/loeb"}
								>
									<span className="align-middle">←</span>
									<img
										style={{ maxWidth: "350px", maxHeight: "100px" }}
										className="align-middle"
										src={race.logo[0].downloadUrl}
										alt=""
									/>
								</Link>
							</h1>
						</Row>
						<Row>
							<Container
								className={`bg-light rounded p-0 p-sm-5`}
								style={{
									border: `4px solid ${
										race.hexColor ? race.hexColor : "#dee2e6"
									}`,
								}}
							>
								<Row>
									<Col
										className="text-start"
										xs={{ span: 12, order: "last" }}
										sm={{ span: 12, order: "last" }}
										md={{ span: 12, order: "last" }}
										lg={{ span: 12, order: "last" }}
										xl={{ span: 4, order: "first" }}
									>
										<Row>
											<Col
												className="text-xs-center pe-sm-2 mt-2 mt-xl-0"
												xs={12}
												sm={12}
												md={12}
												lg={12}
											>
												<h2>{race.name}</h2>
												<p style={{ whiteSpace: "pre-wrap" }}>
													{race.description}
												</p>
											</Col>
										</Row>
									</Col>
									<Col
										xs={{ span: 12, order: "first" }}
										sm={{ span: 12, order: "first" }}
										md={{ span: 12, order: "first" }}
										lg={12}
										xl={{ span: 8, order: "last" }}
										className=""
									>
										<Carousel className="border border-2 border-secondary rounded">
											{race.featuredImages.length > 0 ? (
												race.featuredImages.map((image, idx) => {
													return (
														<Carousel.Item className="" key={idx}>
															<Ratio aspectRatio={66}>
																<img
																	className=""
																	src={image.downloadUrl}
																	alt={image.name}
																	style={{}}
																/>
															</Ratio>
														</Carousel.Item>
													);
												})
											) : (
												<Carousel.Item
													className=""
													key={race.coverImage[0].name}
												>
													<Ratio aspectRatio={66}>
														<img
															className=""
															src={race.coverImage[0].downloadUrl}
															alt={race.coverImage[0].name}
															style={{}}
														/>
													</Ratio>
												</Carousel.Item>
											)}
										</Carousel>
									</Col>
								</Row>
							</Container>
						</Row>
						<Row className="py-3 mt-3 bg-dark rounded">
							{race.facebookUrl ? (
								<SoMeIcon
									url={race.facebookUrl}
									icon={facebookIcon}
									label="Facebook"
								/>
							) : null}
							{race.instagramUrl ? (
								<SoMeIcon
									url={race.instagramUrl}
									icon={instagramIcon}
									label="Instagram"
								/>
							) : null}
							{/*
							 * Snapchat funktion fjernet indtil vi får endpoint til at virke
							 */}
							{/*race.snapchatUsername ? (
                <SnapchatIcon
                  username={race.snapchatUsername}
                  icon={snapcatIcon}
                  label="Snapchat"
                />
              ) : null*/}
							{race.youtubeUrl ? (
								<SoMeIcon
									url={race.youtubeUrl}
									icon={youtubeIcon}
									label="YouTube"
								/>
							) : null}
						</Row>
						<Row className="">
							<Col
								xs={12}
								sm={12}
								md={12}
								lg={6}
								className="py-3 mt-3 me-lg-2 bg-light border border-4 border-secondary rounded"
							>
								<Container>
									<Row>
										<Col className="text-xs-center text-sm-start">
											<h3 className="pb-3">
												Generelt om {race.name}{" "}
												<Badge className="float-end me-2" bg="danger">
													{race.leagueMember ? "Ligaløb" : null}
												</Badge>
											</h3>
											{/*<p className="lead">{race.leagueMember ? "Ligaløb" : null}</p>*/}
											<Row>
												<Col xs={12} sm={12} md={12} lg={6}>
													<label className="fw-bold">Geografi</label>
													<p>{race.geographic}</p>
													<label className="fw-bold">Årstid</label>
													<p>{race.period}</p>
													<label className="fw-bold">
														Krav til at deltagere kan svømme
													</label>
													<p>{race.swimmingRequired ? "Ja" : "Nej"}</p>
													{race.contactEmail ? (
														<>
															<label className="fw-bold">Kontakt email</label>
															<p>{race.contactEmail}</p>{" "}
														</>
													) : null}
												</Col>
												<Col xs={12} sm={12} md={12} lg={6}>
													{race.facts ? (
														<>
															<label className="fw-bold">
																{decode(race.facts).length} facts om {race.name}
															</label>
															<ul>
																{decode(race.facts).map((i) => (
																	<li>{i}</li>
																))}
															</ul>
														</>
													) : null}
													{race.competitionClasses ? (
														<>
															<label className="fw-bold">
																Konkurrence klasser
															</label>
															<ul>
																{decode(race.competitionClasses).map((i) => (
																	<li>{i}</li>
																))}
															</ul>
														</>
													) : null}
												</Col>
											</Row>
											{race.url ? (
												<Button
													href={race.url}
													className="mt-2 ms-0"
													target="_blank"
													style={{
														backgroundColor: race.hexColor
															? race.hexColor
															: "#343a40",
														color:
															tinycolor(race.hexColor).isLight() &&
															race.hexColor
																? "#000"
																: "#FFF",
														borderColor: race.hexColor ? race.hexColor : null,
													}}
												>
													Læs mere om {race.name}
												</Button>
											) : null}
										</Col>
									</Row>
								</Container>
							</Col>
							<Col className="py-3 mt-3 ms-lg-2 bg-secondary rounded">
								<Container>
									<Row>
										<Col className="text-xs-center text-sm-start">
											<h3 className="">Næste løb</h3>
											{race.startDate && race.endDate ? (
												<p className="lead">
													{moment(race.startDate, "YYYY-MM-DD").format("LL")} -{" "}
													{moment(race.endDate, "YYYY-MM-DD").format("LL")}
												</p>
											) : null}
											<label className="fw-bold">Aldersgrænser</label>
											<p>
												{race.minAge}
												{race.maxAge ? ` - ${race.maxAge}` : `+`} år
											</p>
											<label className="fw-bold">Patruljestørrelse</label>
											<p>
												{race.minSquadSize === race.maxSquadSize
													? race.minSquadSize
													: race.minSquadSize + "-" + race.maxSquadSize}
											</p>
											{race.minDistance ? (
												<>
													<label className="fw-bold">Distance</label>
													<p>Ca. {race.minDistance}km</p>
												</>
											) : null}
											{race.participants ? (
												<>
													<label className="fw-bold">Antal deltagere</label>
													<p>{race.participants}</p>
												</>
											) : null}
											{race.registrationUrl ? (
												<Button
													href={race.registrationUrl}
													className="mt-2 ms-0"
													target="_blank"
													style={{
														backgroundColor: race.hexColor
															? race.hexColor
															: "#343a40",
														color:
															tinycolor(race.hexColor).isLight() &&
															race.hexColor
																? "#000"
																: "#FFF",
														borderColor: race.hexColor ? race.hexColor : null,
													}}
												>
													Åben tilmelding
												</Button>
											) : null}
										</Col>
									</Row>
								</Container>
							</Col>
						</Row>
						<Modal
							show={this.state.show}
							size="md"
							onHide={() => setShow(false)}
							backdrop={true}
						>
							<Modal.Header closeButton>
								<Modal.Title>
									Snapchat brugernavn: <i>{race.snapchatUsername}</i>
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Snapcode
									username={race.snapchatUsername}
									width="100%"
									backgroundColor={race.hexColor ? race.hexColor : null}
									circleColor={race.hexColor ? race.hexColor : null}
									dotsColor={
										race.hexColor && tinycolor(race.hexColor).isDark()
											? "#FFFFFF"
											: null
									}
									ghostColor={
										race.hexColor && tinycolor(race.hexColor).isDark()
											? "#FFFFFF"
											: null
									}
								/>
							</Modal.Body>
						</Modal>
					</Container>
				) : (
					<ErrorLayout></ErrorLayout>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	race: state.race,
});

export default connect(mapStateToProps, { getRaceByName })(RaceProfile);
