import React, { Component } from "react";
import { connect } from "react-redux";
import { getRaces } from "../actions/raceActions";
import RaceCard from "./RaceCard";
import { Container, Row } from "react-bootstrap";

class Race extends Component {
  componentDidMount() {
    this.props.getRaces();
  }

  render() {
    const { races } = this.props.race;

    return (
      <Container>
        <Row>
          <h1 className="page-title">Adventurespejdløb i Danmark</h1>
        </Row>
        <Row>
          {races ? (
            races.map((race) => {
              return <RaceCard race={race} key={race.id} />;
            })
          ) : (
            <h1>No races</h1>
          )}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  race: state.race,
});

export default connect(mapStateToProps, { getRaces })(Race);
