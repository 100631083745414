import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";

const searchIcon = <FontAwesomeIcon className="" icon={faSearch} />;
const timesIcon = <FontAwesomeIcon className="" icon={faTimes} />;

function ImagesUploader(props) {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [show, setShow] = useState(false);
  const input = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const value = () => {
    const { value } = props;

    if (!value) {
      return [];
    }

    return Array.isArray(value) ? value : [value];
  };

  const fileList = () => {
    return value().map((item) => ({
      uid: item.id || undefined,
      name: item.name,
      status: "done",
      url: item.downloadUrl,
    }));
  };

  const handleRemove = (id) => {
    props.onChange(value().filter((item) => item.id !== id));
  };

  const handleChange = async (event) => {
    try {
      const files = event.target.files;

      if (!files || !files.length) {
        return;
      }

      let file = files[0];

      /*FileUploader.validate(file, {
        storage: props.storage,
        image: true,
      });*/

      setLoading(true);

      /*file = await FileUploader.upload(file, {
        storage: props.storage,
        image: true,
      });*/

      input.current.value = "";

      setLoading(false);
      props.onChange([...value(), file]);
    } catch (error) {
      input.current.value = "";
      console.error(error);
      setLoading(false);
      //Errors.showMessage(error);
    }
  };

  const doPreviewImage = (image) => {
    setImage({
      src: image.downloadUrl,
      alt: image.name,
    });
    handleShow();
  };

  const doCloseImageModal = () => {
    setImage(null);
    handleClose();
  };

  const { max, readonly } = props;

  const uploadButton = (
    <label
      className="btn btn-primary text-white px-4 mb-2"
      style={{ cursor: "pointer" }}
    >
      + Upload
      <input
        style={{ display: "none" }}
        disabled={loading || readonly}
        accept="image/*"
        type="file"
        onChange={handleChange}
        ref={input}
      />
    </label>
  );

  return (
    <div style={{}}>
      {readonly || (max && fileList().length >= max) ? null : uploadButton}

      {value() && value().length ? (
        <div className="d-flex flex-row flex-wrap">
          {value().map((item) => {
            return (
              <div
                className="mr-2 mb-2 img-card"
                style={{ height: "100px" }}
                key={item.id}
              >
                <img
                  alt={item.name}
                  src={item.downloadUrl}
                  className="img-thumbnail"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                />

                <div className="img-buttons rounded-bottom">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => doPreviewImage(item)}
                  >
                    {searchIcon}
                  </button>

                  {!readonly && (
                    <button
                      type="button"
                      className="btn btn-link ml-2"
                      onClick={() => handleRemove(item.id)}
                    >
                      {timesIcon}
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}

      {image && (
        <>
          <Modal show={show} size="xl" onHide={doCloseImageModal}>
            <Modal.Header closeButton>
              <Modal.Title>{image.alt}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img
                className="modal-content border-0"
                src={image.src}
                alt={image.alt}
              />
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
}

export default ImagesUploader;
