import "./App.css";
import Navbar from "./components/Navbar";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import MyProfile from "./components/profile/MyProfile";
import Race from "./components/Race";
import LigaRules from "./components/Liga/LigaRules";
import PointsOverview from "./components/Liga/PointsOverview";
import Terms from "./components/Terms";
import RaceProfile from "./components/RaceProfile";
import ErrorLayout from "./components/ErrorLayout";
import Footer from "./components/Footer";
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/authActions";
import { Component } from "react";
import PrivateRoute from "./components/PrivateRoute";
import ResetPassword from "./components/auth/resetPassword";

class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (
      <Provider store={store}>
        <div className="App main-container">
          <div className="mb-5">
            <Router>
              <Navbar />
              <Switch>
                <Route path="/" exact>
                  <Redirect to="/loeb" />
                </Route>
                <Route path="/login" component={Login} />
                <Route path="/opret" component={Register} />
                <PrivateRoute path="/minside" component={MyProfile} />
                <Route path="/loeb/:raceName" component={RaceProfile} />
                <Route path="/loeb" component={Race} />
                <Route path="/betingelser" component={Terms} />
                <Route path="/point" component={PointsOverview} />
                <Route path="/regler" component={LigaRules} />
                <Route path="/auth/password-reset" component={ResetPassword} />
                {/*<Route path="/faq" component={FAQ} />*/}
                <Route component={ErrorLayout} />
              </Switch>
            </Router>
          </div>
          <Footer />
        </div>
      </Provider>
    );
  }
}

export default App;
