import { Component } from "react";
import {
  Card,
  Form,
  FormGroup,
  Col,
  Row,
  Button,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { changePassword } from "../../../actions/authActions";

class ChangePassword extends Component {
  state = {
    oldPassword: "",
    newPassword: "",
    repeatNewPassword: "",
    msg: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    changePassword: PropTypes.func.isRequired,
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setIsDisabled = () => {
    const { oldPassword, newPassword, repeatNewPassword } = this.state;
    return (
      oldPassword !== "" &&
      newPassword !== "" &&
      repeatNewPassword !== "" &&
      newPassword === repeatNewPassword
    );
  };

  componentDidUpdate(prevProps) {
    const { error } = this.props;

    if (error !== prevProps.error) {
      if (error.id === "CHANGE_PASSWORD_FAIL") {
        this.setState({ msg: error.msg });
      } else {
        this.setState({ msg: null });
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { oldPassword, newPassword } = this.state;
    const password = { oldPassword, newPassword };

    this.props.changePassword(password);
    //TODO: EROR BESKED
  };

  render() {
    return (
      <Card style={{ textAlign: "left" }} className="mb-3">
        <Card.Header>Skift password</Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col>
                <FormGroup className="mt-3">
                  <Form.Label>Gammelt password</Form.Label>
                  <Form.Control
                    name="oldPassword"
                    onChange={this.onChange}
                    type="password"
                    autoComplete="current-password"
                    id="current-password"
                  />
                </FormGroup>
                <FormGroup className="mt-3">
                  <Form.Label>Nyt password</Form.Label>
                  <Form.Control
                    name="newPassword"
                    onChange={this.onChange}
                    type="password"
                    autoComplete="new-password"
                    id="new-password"
                  />
                </FormGroup>
                <FormGroup className="mt-3">
                  <Form.Label>Gengag nyt password</Form.Label>
                  <Form.Control
                    name="repeatNewPassword"
                    onChange={this.onChange}
                    type="password"
                    autoComplete="repeat-password"
                    id="repeat-password"
                  />
                </FormGroup>
                <FormGroup className="d-grid mt-3">
                  {this.state.msg ? (
                    <Alert variant="danger">{this.state.msg}</Alert>
                  ) : null}
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.handleSubmit}
                    disabled={!this.setIsDisabled()}
                    block
                  >
                    Skift password
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.error,
});

export default connect(mapStateToProps, { changePassword })(ChangePassword);
