import axios from "axios";
import { GET_MY_SQUADS_LIST, CRECATE_SEASON_ID, CRECATE_SQUAD } from "./types";
import { returnErrors } from "./errorActions";

const tenant = process.env.REACT_APP_TENANT;
const baseURL = process.env.REACT_APP_BASEURL + "/tenant/" + tenant;

export const getMySquads = () => async (dispatch, getState) => {
  return await axios
    .get(baseURL + `/squad/findByMember`, setHeaders(getState))
    .then((res) =>
      dispatch({
        type: GET_MY_SQUADS_LIST,
        payload: res.data,
      })
    );
};

export const cretateLeagueID = (payload) => async (dispatch, getState) => {
  return await axios
    .post(
      baseURL + `/squad/createLigaID`,
      { data: payload },
      setHeaders(getState)
    )
    .then((res) =>
      dispatch({
        type: CRECATE_SEASON_ID,
        payload: res.data,
      })
    );
};

export const createNewSquad = (payload) => async (dispatch, getState) => {
  return await axios
    .post(baseURL + `/squad/`, { data: payload }, setHeaders(getState))
    .then((res) =>
      dispatch({
        type: CRECATE_SQUAD,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch(
        returnErrors(
          err.response.data,
          err.response.status,
          "CREATE_SQUAD_ERROR"
        )
      );
    });
};

// Add member to squad
export const addMembersToSquad =
  (payload, squadID) => async (dispatch, getState) => {
    return await axios
      .patch(
        baseURL + `/squad/addMembers/${squadID}`,
        { data: payload },
        setHeaders(getState)
      )
      .catch((err) => {
        dispatch(
          returnErrors(
            err.response.data,
            err.response.status,
            "MXMUMUM_SQUAD_ERROR"
          )
        );
      });
  };

// Config headers and token
const setHeaders = (getState) => {
  // Get token from localstorage
  const token = getState().auth.token;
  // Headers
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  // If token, add to headers
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
};
