import React, { Component } from "react";
import { Nav, Navbar, Container, Row, Col, NavDropdown } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
/* SEARCH BAR USES THIS */
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import Logo from "./Logo";
import { logout } from "../actions/authActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class NavBar extends Component {
	static propTypes = {
		auth: PropTypes.object.isRequired,
		logout: PropTypes.func.isRequired,
	};

	render() {
		const { isAuthenticated } = this.props.auth;

		const authLinks = (
			<>
				<Link className="auth-item pt-1" to="/minside">
					{" "}
					Min side{" "}
				</Link>
				<strong className="text-danger pt-1"></strong>
				<div id="nav-login-btn-split"></div>
				<Link
					className="auth-item pe-1 pt-1"
					to="/"
					onClick={this.props.logout}
				>
					{" "}
					Log ud{" "}
				</Link>
			</>
		);
		const guestLinks = (
			<>
				<Link className="auth-item pt-1 ps-1" to="/opret">
					{" "}
					Opret{" "}
				</Link>
				<strong className="text-danger pt-1"></strong>
				<div id="nav-login-btn-split"></div>
				<Link className="auth-item pt-1 pe-1" to="/login">
					{" "}
					Login{" "}
				</Link>
			</>
		);

		const authMobileLinks = (
			<>
				<Link
					className="auth-item pt-2 ml-4"
					to="/"
					onClick={this.props.logout}
				>
					{" "}
					Log ud{" "}
				</Link>
				<NavLink className="auth-item pt-2 mr-4" to="/minside">
					{" "}
					Min Side{" "}
				</NavLink>
			</>
		);
		const guestMobileLinks = (
			<>
				<NavLink className="auth-item pt-2 ml-4" to="/login">
					{" "}
					Login{" "}
				</NavLink>
				<NavLink className="auth-item pt-2 mr-4" to="/opret">
					{" "}
					Opret{" "}
				</NavLink>
			</>
		);

		return (
			<>
				<div
					className="bg-dark mb-3 header"
					style={{ borderBottom: "5px solid #f50c00" }}
				>
					<Container>
						<Row sm={12}>
							<Col md={12} lg={2}>
								<Navbar.Brand href="/">
									<Logo />
								</Navbar.Brand>
							</Col>
							<Col
								md={12}
								lg={{ span: 3, offset: 6 }}
								xl={{ span: 2, offset: 7 }}
								xxl={{ span: 2, offset: 7 }}
							>
								<div id="nav-login-btn">
									<div className="text-white d-flex justify-content-around">
										{isAuthenticated ? authLinks : guestLinks}
									</div>
								</div>
							</Col>
						</Row>
						<Navbar
							className="pt-0 px-0"
							bg="transparent"
							expand="lg"
							variant="dark"
						>
							<Navbar.Toggle aria-controls="basic-navbar-nav" />
							<Navbar.Collapse id="basic-navbar-nav">
								<Nav className="mr-auto ">
									{/*<NavLink className="auth-item" to="/sign-up" > Opret </NavLink>*/}
									{isAuthenticated ? authMobileLinks : guestMobileLinks}
									<NavLink to="/loeb">Løb</NavLink>
									<NavLink to="/FAQ" hidden>
										FAQ
									</NavLink>
									<NavLink to="/om" hidden>
										Om
									</NavLink>
									<NavLink to="/kontakt" hidden>
										Kontakt
									</NavLink>
									<NavLink to="/spejder" hidden>
										Spejder
									</NavLink>
									<NavLink to="/leder" hidden>
										Leder
									</NavLink>
									<NavLink to="/adventurespejd" hidden>
										Adventurespejd
									</NavLink>
									<NavDropdown title="Liga" className="nav-dropdown">
										<NavLink className="" to="/point">
											Point
										</NavLink>
										<NavLink className="" to="/regler">
											Regler
										</NavLink>
									</NavDropdown>
								</Nav>
								{/* for later usage <Form className="">
										<InputGroup>
											<FormControl type="text" placeholder="Søg..." aria-describedby="searchBtn" className="" />
											<Button style={{borderBottomLeftRadius: "0px", borderTopLeftRadius: "0px", borderLeft: "none"}} variant="outline-secondary" id="searchBtn" ><FontAwesomeIcon icon={faSearch} color='#fff'/></Button>
										</InputGroup>
									</Form>*/}
							</Navbar.Collapse>
						</Navbar>
					</Container>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, {
	logout,
})(NavBar);
