import { Component } from "react";
import {
  Card,
  Form,
  FormGroup,
  Button,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateUser, reLoadUser } from "../../../actions/authActions";
import { clearErrors } from "../../../actions/errorActions";

class Profile extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    msg: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    updateUser: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    reLoadUser: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.clearErrors();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setIsDisabled = () => {
    const { firstName, lastName } = this.state;
    return firstName !== "" || lastName !== "";
  };

  cleanUp = () => {
    this.setState({ firstName: "", lastName: "" });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const user = this.props.auth.user;

    const { firstName, lastName } = this.state;
    const newInfo = {
      firstName: firstName !== "" ? firstName : user.firstName,
      lastName: lastName !== "" ? lastName : user.lastName,
    };

    const isUpdated = this.props.updateUser(newInfo);
    isUpdated.then((res) => {
      if (res.data) {
        this.props.reLoadUser();
        this.setState({ firstName: "", lastName: "" });
        this.props.clearErrors();
      }
    });
  };

  componentDidUpdate(prevProps) {
    const { error } = this.props;

    if (error !== prevProps.error) {
      if (error.id === "USER_UPDATE_FAIL") {
        this.setState({ msg: error.msg });
      } else {
        this.setState({ msg: null });
      }
    }
  }

  render() {
    const { user } = this.props.auth;

    return (
      <Card style={{ textAlign: "left" }} className="mb-3">
        <Card.Header>Brugeroplysninger</Card.Header>
        <Card.Body>
          <Form>
            <Row>
              {this.state.msg ? (
                <Alert variant="danger">{this.state.msg}</Alert>
              ) : null}
              <Col lg={6}>
                <FormGroup>
                  <Form.Label>Fornavn</Form.Label>
                  <Form.Control
                    placeholder={user ? user.firstName : ""}
                    name="firstName"
                    onChange={this.onChange}
                    type="text"
                    value={this.state.firstName}
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Form.Label>Efternavn</Form.Label>
                  <Form.Control
                    placeholder={user ? user.lastName : ""}
                    name="lastName"
                    onChange={this.onChange}
                    type="text"
                    value={this.state.lastName}
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    onChange={this.onChange}
                    type="email"
                    value={user.email}
                    disabled="true"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ textAlign: "right" }}>
              <Col xs={12}>
                <FormGroup>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.handleSubmit}
                    disabled={!this.setIsDisabled()}
                    block
                    className="mt-3"
                  >
                    Opdater
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.error,
});

export default connect(mapStateToProps, {
  updateUser,
  clearErrors,
  reLoadUser,
})(Profile);
