import { Component } from "react";
import { Card, Button, Col, Badge, Row, Ratio } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/da";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faChild } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";

moment.locale("da");
const calenderIcon = <FontAwesomeIcon icon={faCalendarAlt} color="#FFF" />;
const ageIcon = <FontAwesomeIcon icon={faChild} color="#FFF" />;
const geoIcon = <FontAwesomeIcon icon={faMapMarkedAlt} color="#FFF" />;

class RaceCard extends Component {
  render() {
    const race = this.props.race;
    const date =
      moment(race.startDate).format("DD. MMM") +
      " - " +
      moment(race.endDate).format("DD. MMM YYYY");

    return (
      <Col lg="4" md="6" sm="12" className="pb-4 d-flex align-items-stretch">
        <Card>
          <Ratio aspectRatio={66}>
            <Card.Img
              variant="top"
              src={
                race.coverImage.length > 0
                  ? race.coverImage[0].downloadUrl
                  : null
              }
              style={{ height: "100%" }}
            />
          </Ratio>
          <Card.Body className="text-start">
            <Row className="justify-content-between">
              <Col xs={10} sm={10} md={9} lg={8}>
                <Card.Title>{race.name.toUpperCase()}</Card.Title>
              </Col>
              <Col xs={2} sm={2} md={3} lg={4} className="px-0">
                <Badge className="float-end badge-medium me-2" bg="danger">
                  {race.leagueMember ? "Ligaløb" : null}
                </Badge>
              </Col>
            </Row>
            <Card.Text style={{ minHeight: "50px" }}>
              {race.shortDescription}
            </Card.Text>
            <Row className="justify-content-start">
              <Col className="">
                <Badge className="badge-medium mt-1 me-1" bg="info">
                  {calenderIcon}{" "}
                  {race.startDate && race.endDate
                    ? date
                    : "Ikke offentliggjort"}
                </Badge>

                <Badge className="badge-medium mt-1 me-1" bg="info">
                  {ageIcon} {race.minAge}
                  {race.maxAge ? ` - ${race.maxAge}` : `+`} år
                </Badge>

                <Badge className="badge-medium mt-1 me-1" bg="info">
                  {geoIcon} {race.geographic}
                </Badge>
              </Col>
            </Row>
            <Link to={"/loeb/" + race.name}>
              <Button className="mt-2 ms-0 text-white" variant="primary">
                Læs mere om {race.name}
              </Button>
            </Link>
          </Card.Body>
        </Card>
      </Col>
    );
  }
}

export default RaceCard;
