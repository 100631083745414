import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Button, Container, Form, FormGroup, Alert } from "react-bootstrap";
import { clearErrors } from "../../actions/errorActions";
import Logo from "../Logo";
import { resetPassword } from "../../actions/authActions";

class Login extends Component {
	state = {
		password: "",
		msg: null,
	};

	static propTyps = {
		error: PropTypes.object.isRequired,
		clearErrors: PropTypes.func.isRequired,
		resetPassword: PropTypes.func.isRequired,
	};

	componentDidMount() {
		this.props.clearErrors();
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	setIsDisabled = () => {
		const { password } = this.state;
		return password !== "";
	};

	componentDidUpdate(prevProps) {
		const { error } = this.props;

		if (error !== prevProps.error) {
			if (error.id === "LOGIN_FAIL" || error.id === "RESET_PASSWORD_FAIL") {
				this.setState({ msg: error.msg });
			} else {
				this.setState({ msg: null });
			}
		}
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.clearErrors();
		const { password } = this.state;
		const urlParams = new URLSearchParams(window.location.search);
		if (password !== "") {
			const isReset = this.props.resetPassword(
				password,
				urlParams.get("token")
			);
			isReset.then((res) => {
				this.props.history.push("/login");
			});
		}
	};

	render() {
		return (
			<Container style={{ maxWidth: "350px" }}>
				<Logo light={false} className="page-title"></Logo>
				<h3 className="page-title">Log ind</h3>
				{this.state.msg ? (
					<Alert variant="danger">{this.state.msg}</Alert>
				) : null}
				<Form>
					<FormGroup className="mt-3">
						<Form.Control
							placeholder={"Kodeord"}
							name="password"
							onChange={this.onChange}
							type="password"
							autoComplete="current-password"
						/>
					</FormGroup>
					<FormGroup className="d-grid gap-2">
						<Button
							variant="danger"
							type="submit"
							onClick={this.handleSubmit}
							disabled={!this.setIsDisabled()}
							className="mt-3"
						>
							Nulstil password
						</Button>
					</FormGroup>
				</Form>
			</Container>
		);
	}
}
const mapStateToProps = (state) => ({
	error: state.error,
});

export default connect(mapStateToProps, {
	clearErrors,
	resetPassword,
})(Login);
