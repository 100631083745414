import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import Logo from "./Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

const facebookIcon = (
  <FontAwesomeIcon
    className="fa-3x p-1"
    icon={faFacebook}
    /*color='#3b5998'*/ color="#1769ff"
  />
);
const InstagramIcon = (
  <FontAwesomeIcon
    className="fa-3x p-1"
    icon={faInstagram}
    /*color='#dd2a7b'*/ color="#E1306C"
  />
);

const Footer = () => (
  <footer className="bg-dark">
    <Container>
      <Row className="justify-content-between text-center fs--1 mt-4">
        <Col className="footer-col text-white" xs={12} sm={12} md="auto">
          <h3>Kontakt</h3>
          <p className="mb-0 texts-600">kontakt@adventurespejd.dk</p>
        </Col>
        <Col className="footer-col" sm="auto">
          <a
            href="https://www.facebook.com/adventurespejd"
            target="_blank"
            rel="noreferrer"
            className="me-3"
          >
            {facebookIcon}
          </a>
          <a
            href="https://www.instagram.com/adventurespejd"
            target="_blank"
            rel="noreferrer"
          >
            {InstagramIcon}
          </a>
        </Col>
        <Col className="footer-col" sm="auto">
          <Logo />
        </Col>
      </Row>
      <Row>
        <h6 className="text-center text-white">
          &copy; {new Date().getFullYear()} Adventurespejd - Udviklet af{" "}
          <a
            className="text-warning"
            style={{ textDecoration: "none" }}
            href="https://invictusloebet.dk"
            target={"_blank"}
            rel="noreferrer"
          >
            Invictus
          </a>
        </h6>
      </Row>
    </Container>
  </footer>
);

export default Footer;
