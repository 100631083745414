export const color = {
  PRIMARY : "primary",
  SECONDARY : "secondary",
  SUCCESS : "success",
  DANGER : "danger",
  WARNING : "warning",
  INFO : "info",
  LIGHT : "light",
  DARK : "dark"
} 

export const outline = {
  PRIMARY : "outline-primary",
  SECONDARY : "outline-secondary",
  SUCCESS : "outline-success",
  DANGER : "outline-danger",
  WARNING : "outline-warning",
  INFO : "outline-info",
  LIGHT : "outline-light",
  DARK : "outline-dark"
} 
