import { faTrophy, faMedal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Function to get current season from array of seasons
export const getCurrentSeason = (seasons) => {
  const currentSeason = seasons.filter((season) => {
    return (
      new Date(season.startDate) < new Date() &&
      new Date(season.endDate) > new Date()
    );
  });
  return currentSeason[0];
};

// Function to sort array of seasons by start date
export const sortSeasonsByStartDate = (seasons) => {
  return seasons.sort((a, b) => {
    return new Date(a.startDate) - new Date(b.startDate);
  });
};

// Function to structure scores in JSON format to Point table
export const structureScores = (scoreArray, currentSeason) => {
  // Filter scores to only include current season
  const filteredScores = scoreArray.filter((score) => {
    return score.season.id === currentSeason.id;
  });

  // Sort scores by created date
  filteredScores.sort((a, b) => {
    return new Date(a.createdAt) - new Date(b.createdAt);
  });

  // create unique array of races from filtered scores
  const raceNamePlaceholder = [];
  const raceList = [];
  filteredScores.forEach((score) => {
    if (!raceNamePlaceholder.includes(score.race.id)) {
      raceNamePlaceholder.push(score.race.id);
      raceList.push(score.race);
    }
  });

  // create 2d array of race name and logo url
  const logoList = raceList.map((race) => {
    return [race.name, race.logo[0].downloadUrl];
  });

  // create Set of squad names from filtered scores
  const squadNames = [
    ...new Set(filteredScores.map((score) => score.squad.name)),
  ];

  const squadScores = {};

  // create object of squad names and scores for every race
  squadNames.forEach((squad) => {
    const scores = filteredScores.filter((score) => {
      return score.squad.name === squad;
    });

    const points = {};
    let pointArray = [];
    // create dynamic object with points for each race
    raceList.forEach((race) => {
      scores.forEach((score) => {
        if (score.race.name === race.name && score.squad.name === squad) {
          points[race.name] = score.point;
          pointArray.push(score.point);
        }
      });
      if (points[race.name] === undefined) {
        points[race.name] = null;
      }
    });

    // sort pointArray
    pointArray.sort((a, b) => {
      return b - a;
    });

    let total = 0;

    // Get any minus point, add to total and remove fom pointArray
    for (let index = 0; index < pointArray.length; index++) {
      if (pointArray[index] < 0) {
        total += pointArray[index];
        pointArray.splice(index, 1);
      }
    }

    // find the sum of the first 6 places in pointArray
    for (let index = 0; index < pointArray.length; index++) {
      const point = pointArray[index];
      if (index < 6 && point !== null) {
        total += point;
      }
    }

    // add squad if total is more than 0
    if (total > 0) {
      squadScores[squad] = {
        points: points,
        total: total,
        squad: scores[0].squad,
      };
    }
  });

  // add space to squad name to enable right sorting then names is e.g a number
  Object.keys(squadScores).forEach((key) => {
    squadScores[key + " "] = squadScores[key];
    delete squadScores[key];
  });

  //sort squadScores in decending order by value total
  const sortedSquadScores = Object.keys(squadScores)
    .sort((a, b) => squadScores[b].total - squadScores[a].total)
    .reduce((r, k) => {
      r[k] = squadScores[k];
      return r;
    }, {});

  const scoreElement = {
    logos: logoList,
    squads: sortedSquadScores,
  };

  return scoreElement;
};

// Function to set fontawesome icons for top 3 squads
export const setIcon = (index) => {
  switch (index) {
    case 0:
      return <FontAwesomeIcon icon={faTrophy} color="gold" />;
    case 1:
      return <FontAwesomeIcon icon={faMedal} color="silver" />;
    case 2:
      return <FontAwesomeIcon icon={faMedal} color="brown" />;
    default:
      return null;
  }
};
