import { combineReducers } from "redux";
import raceReducer from "./raceReducer";
import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import faqReducer from "./faqReducer";
import squadReducer from "./squadReducer";
import seasonReducer from "./seasonReducer";

export default combineReducers({
  race: raceReducer,
  error: errorReducer,
  auth: authReducer,
  faq: faqReducer,
  squad: squadReducer,
  season: seasonReducer
});
