import { GET_MY_OPEN_SQUADS_LIST, GET_SEASONS } from "../actions/types";

const initialState = {
  myOpenSeasonList: [],
  seasons: [],
};

const seasonReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_OPEN_SQUADS_LIST:
      return {
        ...state,
        myOpenSeasonList: action.payload,
      };
    case GET_SEASONS:
      return {
        ...state,
        seasons: action.payload,
      };
    default:
      return state;
  }
};

export default seasonReducer;
