import axios from "axios";
import { returnErrors } from "./errorActions";
import {
  RACE_LOADING,
  GET_RACES,
  GET_RACE,
  GET_MY_RACE_LIST,
  GET_RACE_BY_NAME,
} from "./types";

const tenant = process.env.REACT_APP_TENANT;
const baseURL = process.env.REACT_APP_BASEURL + "/tenant/" + tenant;

export const getRaces = () => (dispatch, getState) => {
  dispatch({ type: RACE_LOADING });

  axios
    .get(baseURL + "/race/publicOverview", setHeaders(getState))
    .then((res) =>
      dispatch({
        type: GET_RACES,
        payload: res.data,
      })
    );
  // TODO: Catch error
};

export const getRaceByID = (raceID) => async (dispatch, getState) => {
  dispatch({ type: RACE_LOADING });

  return await axios
    .get(baseURL + `/race/${raceID}`, setHeaders(getState))
    .then((res) =>
      dispatch({
        type: GET_RACE,
        payload: res.data,
      })
    );
};

export const getRacesByMember = () => async (dispatch, getState) => {
  return await axios
    .get(baseURL + `/race/findByMember`, setHeaders(getState))
    .then((res) =>
      dispatch({
        type: GET_MY_RACE_LIST,
        payload: res.data,
      })
    );
};

export const updateRace = (payload, raceID) => async (dispatch, getState) => {
  const body = { data: payload };
  try {
    return await axios.put(
      baseURL + `/race/${raceID}`,
      body,
      setHeaders(getState)
    );
  } catch (err) {
    dispatch(
      returnErrors(err.response.data, err.response.status, "RACE_UPDATE_FAIL")
    );
  }
};

export const getRaceByName = (raceName) => (dispatch, getState) => {
  dispatch({ type: RACE_LOADING });

  axios
    .get(baseURL + "/race/public/" + raceName, setHeaders(getState))
    .then((res) =>
      dispatch({
        type: GET_RACE_BY_NAME,
        payload: res.data,
      })
    );
  // TODO: Catch error
};

// Config headers and token
const setHeaders = (getState) => {
  // Get token from localstorage
  const token = getState().auth.token;
  // Headers
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  // If token, add to headers
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
};
