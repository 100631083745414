import axios from "axios";
import { GET_ALL_SCORE } from "./types";

const tenant = process.env.REACT_APP_TENANT;
const baseURL = process.env.REACT_APP_BASEURL + "/tenant/" + tenant;

export const getAllScore = () => async (dispatch, getState) => {
  return await axios.get(baseURL + `/score`, setHeaders(getState)).then((res) =>
    dispatch({
      type: GET_ALL_SCORE,
      payload: res.data,
    })
  );
};

// Config headers and token
const setHeaders = (getState) => {
  // Get token from localstorage
  const token = getState().auth.token;
  // Headers
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  // If token, add to headers
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
};
