import { Component } from "react";
import { Card, Button, Row, Col, Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { deleteUser } from "../../../actions/authActions"

class DangerZone extends Component {
  state = {
    modalShow: false
  }

  static propTypes = {
		auth: PropTypes.object.isRequired,
    deleteUser: PropTypes.func.isRequired
	}

  handleClose = () => this.setState({modalShow: false})
  handleShow = () => this.setState({modalShow: true})

  deleteUser = () => {
    this.props.deleteUser(this.props.auth.user.email);
  }

  sendEmail = () => {
    const {user} = this.props.auth;
    const email = "kontakt@adventurespejdt.dk";
    const subject = "Konto ønskes slettet";
    const body = `Hej Adventurespejdt liga \n Vil vi venligst slette denne konto: ${user.email}`

    window.open(`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`)
  }

  render(){
    const {modalShow} = this.state;
    const {user} = this.props.auth;
    const allowedToDeleateOwenUser = false;

    const DeleteUserFaction = (
      <>
        <Card style={{textAlign: "left"}} className="mb-3">
          <Card.Header>Danger Zone</Card.Header> 
          <Card.Body>
            <Row>
              <Col className="d-grid gap-2">
                <h5>Slet bruger</h5>
                <p className="fs--1">Når du har slettet en konto, er der ingen vej tilbage. Vær sikker.</p>
                <Button onClick={this.handleShow} variant="danger" block>
                  Slet bruger
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>

         <Modal show={modalShow} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Slet bruger</Modal.Title>
        </Modal.Header>
        <Modal.Body>Er du sikker på du vil slette brugeren <span class="text-warning fw-bold">{user.email}</span> </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Annuler
          </Button>
          <Button disabled="true" variant="danger" onClick={this.deleteUser}>
            Slet bruger | Funktion på vej
          </Button>
        </Modal.Footer>
      </Modal>
      </>
    )

    const requestDeleteFraction = (
      <>
        <Card style={{textAlign: "left"}} className="mb-3">
          <Card.Header>Danger Zone</Card.Header> 
          <Card.Body>
            <Row>
              <Col className="d-grid gap-2">
                <h5>Slet bruger</h5>
                <p className="fs--1">Hvis du ønsker at få slettet din bruger, så klik på knappen her under for at informere os.</p>
                <p>BEMÆRK email afsenderen skal stemmeoverens med den bruger der ønskes sletet</p>
                <Button onClick={this.sendEmail} variant="danger" block>
                  Slet bruger
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    )

    return (
      <>
      {allowedToDeleateOwenUser ? DeleteUserFaction : requestDeleteFraction}
      </>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, {deleteUser}) (DangerZone)