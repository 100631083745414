import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, auth, ...rest }) => {

  // Add your own authentication on the below line.
  const {isAuthenticated} = auth;

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps) (PrivateRoute)