import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import {
	Button,
	Container,
	Form,
	FormGroup,
	Alert,
	Modal,
} from "react-bootstrap";
import {
	login,
	loadUser,
	sendPasswordResetEmail,
} from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import { Link } from "react-router-dom";
import Logo from "../Logo";

class Login extends Component {
	state = {
		email: "",
		password: "",
		msg: null,
		showResetPasswordModal: false,
		resetEmail: "",
	};

	static propTyps = {
		isAuthenticated: PropTypes.bool,
		error: PropTypes.object.isRequired,
		login: PropTypes.func.isRequired,
		loadUser: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired,
		sendPasswordResetEmail: PropTypes.func.isRequired,
	};

	componentDidMount() {
		this.props.clearErrors();
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	setIsDisabled = () => {
		const { email, password } = this.state;
		return email !== "" && password !== "";
	};

	componentDidUpdate(prevProps) {
		const { error, isAuthenticated } = this.props;

		if (error !== prevProps.error) {
			if (
				error.id === "LOGIN_FAIL" ||
				error.id === "CHANGE_PASSWORD_EMAIL_SEND_FAIL"
			) {
				this.setState({ msg: error.msg });
			} else {
				this.setState({ msg: null });
			}
		}

		if (isAuthenticated) {
			this.props.loadUser();
			this.props.clearErrors();
			this.props.history.push("/");
		}
	}

	handleSubmit = (e) => {
		e.preventDefault();

		const { email, password } = this.state;
		const user = { email, password };

		this.props.login(user);
	};

	handleResetPassword = (e) => {
		this.props.clearErrors();
		const emailRegex =
			"^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$";
		if (
			this.state.resetEmail !== "" &&
			this.state.resetEmail.match(emailRegex)
		) {
			this.props.sendPasswordResetEmail(this.state.resetEmail);
			this.setState({ showResetPasswordModal: false });
			this.setState({ resetEmail: "" });
		} else {
			this.setState({ msg: "Email er ikke gyldig" });
		}
	};

	render() {
		const resetPasswordRequest = (
			<Modal
				show={this.state.showResetPasswordModal}
				onHide={() => this.setState({ showResetPasswordModal: false })}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Nulstil password</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<FormGroup>
							<Form.Label>Email</Form.Label>
							<Form.Control
								type="email"
								name="resetEmail"
								id="resetEmail"
								placeholder="Email"
								className="mb-3"
								onChange={this.onChange}
								value={this.state.resetEmail}
							/>
							<p>
								Du vil modtage en mail med et link til nulstilling af password
							</p>
							<Button color="dark" block onClick={this.handleResetPassword}>
								Send
							</Button>
						</FormGroup>
					</Form>
				</Modal.Body>
			</Modal>
		);

		return (
			<Container style={{ maxWidth: "350px" }}>
				<Logo light={false} className="page-title"></Logo>
				<h3 className="page-title">Log ind</h3>
				{this.state.msg ? (
					<Alert variant="danger">{this.state.msg}</Alert>
				) : null}
				<Form>
					<FormGroup>
						<Form.Control
							placeholder={"Email"}
							name="email"
							onChange={this.onChange}
							type="email"
							autoComplete="email"
						/>
					</FormGroup>
					<FormGroup className="mt-3">
						<Form.Control
							placeholder={"Kodeord"}
							name="password"
							onChange={this.onChange}
							type="password"
							autoComplete="current-password"
						/>
					</FormGroup>
					<FormGroup className="d-grid gap-2">
						<Button
							variant="danger"
							type="submit"
							onClick={this.handleSubmit}
							disabled={!this.setIsDisabled()}
							className="mt-3"
						>
							Log ind
						</Button>
						<Link to="/opret">
							<Button
								variant="secondary"
								style={{ width: "100%" }}
								className=""
							>
								Opret bruger
							</Button>
						</Link>
						<Link
							onClick={() => {
								this.setState({ showResetPasswordModal: true });
							}}
						>
							<p>Glemt password</p>
						</Link>
					</FormGroup>
				</Form>
				{resetPasswordRequest}
			</Container>
		);
	}
}
const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	error: state.error,
});

export default connect(mapStateToProps, {
	login,
	loadUser,
	clearErrors,
	sendPasswordResetEmail,
})(Login);
