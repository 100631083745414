import React, { Component } from "react";
import {Container} from 'react-bootstrap';
import asIllustration from '../assets/images/adventurespejd.png';

class ErrorLayout extends Component {
    render(){
        return ( 
					<Container>
						<h1 className="page-title">404 - Siden findes ikke</h1>
						<img style={{width: "40%", maxWidth: "350px", maxHeight: "450px" }} src={asIllustration} alt="Adventurespejd illustration" /> 
						<h3 className="pt-5">Er du faret vild? Tjek om du har vendt kortet rigtigt</h3>
					</Container>
				)
    }
}

export default ErrorLayout;