import {
  FAQ_LOADING,
  GET_FAQ
} from '../actions/types';

const initialState = {
  faq: [],
  loading: false
}

const faqReducer = (state = initialState, action ) => {
  switch(action.type){
    case FAQ_LOADING:
      return{
        ...state,
        loading: true
      };
    case GET_FAQ:
      return{
        ...state,
        faq: action.payload,
        loading: false
      }
    default:
      return state;
  }
}

export default faqReducer;