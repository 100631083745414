import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Button, Form, FormGroup, Alert, Container } from "react-bootstrap";
import { register } from "../../actions/authActions";
import { loadUser } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import { Link } from "react-router-dom";
import Logo from "../Logo";

class Register extends Component {
  state = {
    email: "",
    newPassword: "",
    repeatNewPassword: "",
    firstName: "",
    lastName: "",
    acceptTerms: false,
    msg: null,
  };

  static propTyps = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.clearErrors();
  }

  onChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  setIsDisabled = () => {
    const {
      email,
      newPassword,
      repeatNewPassword,
      firstName,
      lastName,
      acceptTerms,
    } = this.state;
    return (
      email !== "" &&
      newPassword !== "" &&
      firstName !== "" &&
      lastName !== "" &&
      newPassword === repeatNewPassword &&
      acceptTerms === true
    );
  };

  componentDidUpdate(prevProps) {
    const { error, isAuthenticated } = this.props;

    if (error !== prevProps.error) {
      if (error.id === "REGISTER_FAIL") {
        this.setState({ msg: error.msg });
      } else {
        this.setState({ msg: null });
      }
    }

    if (isAuthenticated) {
      this.props.loadUser();
      this.props.clearErrors();
      this.props.history.push("/");
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { email, newPassword, firstName, lastName } = this.state;
    const newUser = { email, password: newPassword, firstName, lastName };

    this.props.register(newUser);
  };

  render() {
    return (
      <Container style={{ maxWidth: "350px" }}>
        <Logo light={false} className="page-title"></Logo>
        <h3 className="page-title">Opret bruger</h3>
        {this.state.msg ? (
          <Alert variant="danger">{this.state.msg}</Alert>
        ) : null}
        <Form>
          <FormGroup>
            <Form.Control
              placeholder={"Fornavn"}
              name="firstName"
              onChange={this.onChange}
              type="text"
              autoComplete="given-name"
              id="firstName"
            />
          </FormGroup>
          <FormGroup className="mt-3">
            <Form.Control
              placeholder={"Efternavn"}
              name="lastName"
              onChange={this.onChange}
              type="text"
              autoComplete="family-name"
              id="lastName"
            />
          </FormGroup>
          <FormGroup className="mt-3">
            <Form.Control
              placeholder={"Email"}
              name="email"
              onChange={this.onChange}
              type="email"
              autoComplete="email"
            />
          </FormGroup>
          <FormGroup className="mt-3">
            <Form.Control
              placeholder={"Password"}
              name="newPassword"
              onChange={this.onChange}
              type="password"
              autoComplete="new-password"
              id="new-password"
            />
          </FormGroup>
          <FormGroup className="mt-3">
            <Form.Control
              placeholder={"Gentag password"}
              name="repeatNewPassword"
              onChange={this.onChange}
              type="password"
              autoComplete="repeat-password"
              id="repeat-password"
            />
          </FormGroup>
          <FormGroup className="mt-3">
            <Form.Check
              type="checkbox"
              name="acceptTerms"
              onChange={this.onChange}
              id="acceptTerms"
              label={
                <>
                  Jeg accepterer{" "}
                  <Link to="/betingelser">vilkår og betingelser</Link>
                </>
              }
            />
          </FormGroup>
          <FormGroup className="d-grid gap-2">
            <Button
              variant="danger"
              type="submit"
              onClick={this.handleSubmit}
              disabled={!this.setIsDisabled()}
              block
              className="mt-3"
            >
              Opret bruger
            </Button>
            <Link to="/login">
              <Button
                variant="secondary"
                style={{ width: "100%" }}
                className=""
              >
                Log ind
              </Button>
            </Link>
          </FormGroup>
        </Form>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
});

export default connect(mapStateToProps, {
  register,
  loadUser,
  clearErrors,
})(Register);
