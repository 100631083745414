import {
  GET_MY_SQUADS_LIST,
  CRECATE_SEASON_ID,
  CRECATE_SQUAD,
} from "../actions/types";

const initialState = {
  mySquadsList: [],
  seasonID: "",
  newSquad: {}
}

const squadReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_SQUADS_LIST:
      return {
        ...state,
        mySquadsList: action.payload
      };
    case CRECATE_SEASON_ID:
      return {
        ...state,
        seasonID: action.payload
      };
    case CRECATE_SQUAD:
      return {
        ...state,
        newSquad: action.payload
      };
    default:
      return state;
  }
}

export default squadReducer;