import React, {Component, Fragment} from "react";
import { Row, Col } from "react-bootstrap";
import Profile from "./myProfileContent/Profile";
import ChangePassword from "./myProfileContent/ChangePassword";
import DangerZone from "./myProfileContent/DangerZone";

class MySettings extends  Component{
    render() {
        return (
            <Fragment>
                <Profile />
                <Row>
                    <Col lg="6"> <ChangePassword /> </Col>
                    <Col lg="6"> <DangerZone /> </Col>
                </Row>
            </Fragment>
        );
    }

}
export default MySettings;